import React, {useEffect, useState} from 'react'
import {AppBar, Box, ButtonGroup, CssBaseline, Grid, Stack, Tab, Typography} from '@mui/material'
import {accordeonBox, dialog} from 'src/styles/styles'
import API from 'src/features/App/API'
import P0102API from './API'
import {useDispatch, useSelector} from 'react-redux'
import Solicitud from "src/features/P01Solicitud/subcomponents/Solicitud";
import {
  handCompletarTareaCoordinador,
  handleClear,
  handleSaveSolicitud
} from "src/features/App/sliceApp";
import {SectionTitle} from 'src/components/SectionTitle'
import {MySendButton} from 'src/components/MySendButton'
import {format} from 'date-fns'
import {es} from 'date-fns/locale'
import {MyGobackButton, MySaveButton} from 'src/components/MyCommonButtons'
import {MySubtitle} from 'src/components/MySubtitle'
import {MyReadOnlyTextField} from 'src/components/MyReadOnlyTextField'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import Toolbar from "@mui/material/Toolbar";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {f} from "../../commons";
import {P0102PayloadRO} from 'src/features/P01Solicitud/P0102PayloadRO'
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices'
import {MyButtonBacan3} from "../../components/MyButtonBacan3";
import {MyFileUploadButton} from "../../components/MyFileUploadButton";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import {MyTagged2Docx} from "../../components/MyTagged2Docx";
import {PageOrientation} from "docx";
import GLOBALS, {ENV} from 'src/features/App/globals'
import {P0102Expediente} from "./P0102Expediente";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({id}) => {
  const dispatch = useDispatch()
  const perfilUsuario = useSelector(state => state.app.perfilUsuario)
  const section = `InformeTecnico${perfilUsuario?.perfil?.organizacion?.nombreOrganizacion}`
  const bandeja = useSelector(state => state.app.bandeja)
  const instanciaTarea = useSelector(state => state.app.instanciaTarea)
  const instanciaProceso = useSelector(state => state.app.instanciaProceso)
  const esContrato = instanciaTarea?.tipoInstanciaProceso === 'Contrato'
  const solicitud = {...instanciaProceso.solicitud}
  let payload = JSON.parse(!!instanciaProceso?.solicitud?.payload?instanciaProceso?.solicitud?.payload:'{}')
  payload.solicitudId = solicitud.id
  const funcionarios = useSelector(state => state.app.funcionarios)
  const today = format(new Date(), 'dd-MMMM-yyyy', {locale: es})
  const emptyPayload = {
    fecha:         today,
    serial:        '',
    identificador: solicitud.numeroSolicitud,
    docxGenerado:  false,
    docx:          '',
    pdf:           '',
    docxLded:      '',
    pdfLded:       '',

    // firma electronica ---\\
    pdfSigned:       '',
    firmando:        false,
    firmaTareaId:    0,
    firmado:         false,
    // firma electronica ---//
  }
  let myPayload = {
    "55000023_Activity_ElaborarInformeSenadiWF15": payload?.Solicitud?.target?.payload,
  }[instanciaTarea?.tareaCodigoTarea]

  if(!f.isValid(myPayload)) {
    myPayload = payload
  } else {
    myPayload = JSON.parse(myPayload)
  }
  const [formValues, setFormValues] = useState({...emptyPayload, ...payload[section]})
  const getOnSave = (setCounter, fv=null) => {
    return () => {
      payload[section] = f.isValid(fv)?fv:formValues
      dispatch(handleSaveSolicitud(instanciaProceso?.id, payload, () => setCounter(0)))
    };
  }
  const handleChange = (e) => API.handleChange(e, bandeja, setFormValues, formValues)
  const nombreOrganizacion = perfilUsuario?.perfil?.organizacion?.nombreOrganizacion
  const informeTecnico = {
    'Senadi':   !esContrato?'56015020-1':'56015020-2',
    'Senescyt': !esContrato?'56015003-1':'56015003-2',
  }[nombreOrganizacion]
  const codigoAutorizador = {
    'Senadi':   1148,
    'Senescyt': 1139,
  }[nombreOrganizacion]
  const codigoCoordinador = {
    'Senadi':   1148,
    'Senescyt': 164,
  }[nombreOrganizacion]
  const autorizador = funcionarios.filter(it => it.idPerfil === codigoAutorizador)[0]
  const coordinador = funcionarios.filter(it => it.idPerfil === codigoCoordinador)[0]
  const [counter, setCounter] = useState(-1)
  useEffect(() => { setCounter(counter + 1) }, [formValues])
  if(!!instanciaProceso.id && !!instanciaTarea.id) {
    const toBPM = {
      '55000015_Activity_ElaborarInformeTecnicoSenescytWF0102': {
        "criterioTecnicoSenescyt": false,
        "informeCompletoSenescyt": true,
        "ampliacionInformacionSenescyt": false
      },
      '55000015_Activity_ElaborarInformeTecnicoSenadiWF0102': {
        "criterioTecnicoSenadi": false,
        "informeCompletoSenadi": true,
        "ampliacionInformacionSenadi": false
      },
      // '55000002_Activity_ElaborarInformeTecnicoMaate': {
      //   "criterioTecnicoMaate": false,
      //   "informeCompletoMaate": true,
      //   "informacionTerritorioMaate": false,
      //   "amplicacionInformacionMaate": false
      // },
      '55000017_Activity_ElaborarInformeTecnicoSenadiWF0405': {
        "criterioTecnicoSenadi": false,
        "informeCompletoSenadi": true,
        "ampliacionInformacionSenadi": false,
        "legitimoPoseedorSenadi": false,
      }
    }[instanciaTarea?.tareaCodigoTarea]
    const formTitle = {
      '55000015_Activity_ElaborarInformeTecnicoSenadiWF0102': 'Dictamen técnico'
    }[instanciaTarea?.tareaCodigoTarea] ?? 'Informe Técnico'
    const [myTab, setMytab] = useState('1');
    const handleChangeTab = (event, newTab) => { setMytab(newTab) }
    const subfolder = `informe-tecnico-${nombreOrganizacion}`
    const filename = `informe-tecnico-${formValues.identificador}-${nombreOrganizacion}`
    const filenameDOCX = `${filename}.docx`
    const filenameDOCXuploaded = `${filename}-upld.docx`
    const filenamePDF = `${filename}.pdf`
    const filenamePDFsigned = `${filename}-firmado.pdf`
    const labelTab2 = ['55000002_Activity_ElaborarInformeTecnicoSenescyt','55000015_Activity_ElaborarInformeTecnicoSenadiWF0102'].includes(id)?'Solicitud':'Solicitud Modificatoria'
    const labelTab3 = ['55000002_Activity_ElaborarInformeTecnicoSenescyt','55000015_Activity_ElaborarInformeTecnicoSenadiWF0102'].includes(id)?'Expediente':'Expediente Modificatoria'

    // firma electronica ---\\
    useEffect(() => {
      if(formValues.firmando && !formValues.firmado) {
        API.testFirmarPdf(instanciaTarea.id).then((res) => {
          if(res?.resultado) {
            // setFormValues({...formValues, firmando: false, firmado: true})
            console.log(' . .archivo firmado ', filenamePDFsigned)
            console.log(' . . . . .resultado ', res?.resultado)
          }
        })
      }
    },[])
    useEffect(() => {
      if(formValues.firmando) {
        getOnSave(setCounter)()
      }
    },[formValues.firmando])
    // firma electronica ---//

    // console.log(':: firma :: ', JSON.stringify({id:instanciaTarea.id, firmaTareaId:formValues.firmaTareaId, pdfSigned: formValues.pdfSigned, firmando: formValues.firmando, firmado: formValues.firmado}))
    // console.log(':: - - ', formValues.firmado, formValues.firmando)

    return <>
      <Box sx={{...accordeonBox.container, pt:'2rem', height: '100%'}}>
        <AppBar position='fixed' color='primary' elevation={0} sx={{top:'64px', bottom:'auto', margin: 0, padding:0, backgroundColor:'rgba(148, 193, 32, 0.8)'}} >
          <SectionTitle title={formTitle} />
        </AppBar>
        <TabContext value={myTab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
              <Tab label="Formulario" value="1" />
              <Tab label={labelTab2} value="2" />
              <Tab label={labelTab3} value="3" />
              {
                f.isValid(payload?.solicitudOriginal?.payloadSolicitud) ?
                  <Tab label={`Solicitud original`} value="4"/> : null
              }
              {
                f.isValid(payload?.solicitudOriginal?.payloadSolicitud) ?
                  <Tab label={`Expediente original`} value="5"/> : null
              }
            </TabList>
          </Box>
          <TabPanel value="1">
            <Grid container spacing={1} sx={accordeonBox.container2}>
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Solicitud'} />
              </Grid>
              <Solicitud solicitud={solicitud}/>
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Informe'} />
              </Grid>
              <Grid item xs={3} sx={{pl:'4rem'}}>
                <Box sx={{pl:'1.8rem'}}>
                  <MyReadOnlyTextField id={'fecha'}
                                       label={'Fecha'}
                                       icon={<CalendarMonthIcon sx={{fontSize: '14px', color:'silver', mr:'0.8rem'}} />}
                                       value={formValues.fecha} />
                </Box>
              </Grid>
              <Grid item xs={9} >
                <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1} style={{padding:'0 24px 0 8rem'}}>
                  {
                    formValues.serial === ''&&
                    <ButtonGroup variant={'contained'}>
                      <MyButtonBacan3 label={'GENERAR SERIAL'}
                                      width={'11rem'}
                                      bgColor1={'rgb(148 193 32)'}
                                      onClick={() => {
                                        // const tipo = instanciaProceso.tipoInstanciaProceso === 'Contrato'?'CAPC':'PIC'
                                        const tipo = 'ITSYT'
                                          API.secuenciaSet(tipo).then((ser) => {
                                          if(f.isValidNotEmpty(ser.codigoSecuencia)) {
                                            const serial = `${ser.codigoSecuencia}${GLOBALS.year}-${ser.numeroSecuencia}`
                                            getOnSave(setCounter, {...formValues, serial})()
                                            setFormValues({...formValues, serial})
                                            setCounter(0)
                                          }
                                        })
                                      }}
                                      icon={RestartAltIcon}/>
                    </ButtonGroup>
                  }
                  {
                    formValues.docx !== '' &&
                      <ButtonGroup variant={'contained'}>
                            <MyButtonBacan3 label={''}
                                            width={'3rem'}
                                            onClick={() => {
                                              setFormValues({
                                                ...formValues,
                                                docx:     '',
                                                pdf:      '',
                                                docxLded: '',
                                                pdfLded:  '',

                                                // firma -- \\
                                                firmaTareaId: 0,
                                                pdfSigned:    '',
                                                firmando:     false,
                                                firmado:      false,
                                                // firma -- //
                                              })
                                            }}
                                            disabled={formValues.firmado || formValues.firmando}
                                            icon={RestartAltIcon}/>
                      </ButtonGroup>

                  }
                  {
                    formValues.docx === '' && formValues.serial !== '' &&
                      <ButtonGroup variant={'contained'}>
                        <MyButtonBacan3 label={'Generar Informe'}
                                        width={'11rem'}
                                        onClick={() => {
                                          API.fetchPlantila(informeTecnico).then(taggeDoc => {
                                            const doc = MyTagged2Docx(taggeDoc?.contenidoPlantilla, nombreOrganizacion, P0102API.mapping({
                                              solicitudId: instanciaProceso?.solicitud?.numeroSolicitud,
                                              payload,
                                              autorizador,
                                              coordinador,
                                              perfilUsuario,
                                              nombreOrganizacion,
                                              section: payload[section]
                                            }), PageOrientation.PORTRAIT, instanciaProceso?.solicitud?.nombreProyecto)
                                            API.genDocxAnPdf({
                                              solicitudId: instanciaProceso?.solicitud?.id,
                                              document: doc,
                                              formValues,
                                              setFormValues,
                                              subfolder,
                                              filename: filenameDOCX,
                                              nombreOrganizacion,
                                            })
                                          })
                                        }}
                                        icon={MiscellaneousServicesIcon} />
                      </ButtonGroup>
                  }
                  {
                    formValues.docx !== '' &&
                      <ButtonGroup variant={'contained'}>
                        <MyButtonBacan3 label={'DOCX'}
                                        icon={FileDownloadIcon}
                                        onClick={() => {
                                          API.fetchDownloadDOCX({
                                            solicitudId: instanciaProceso?.solicitud?.id,
                                            subfolder,
                                            filename: (formValues.docxLded !== '') ? formValues.docxLded : formValues.docx,
                                          })
                                        }}
                                        toolTip={'Descargar informe técnico en formato docx'}
                                        width={'5rem'} />
                        <MyButtonBacan3 label={'PDF'}
                                        icon={FileDownloadIcon}
                                        onClick={() => API.fetchDownloadPDF({
                                          solicitudId: instanciaProceso?.solicitud?.id,
                                          subfolder,
                                          filename: (formValues.pdfLded !== '') ? formValues.pdfLded : formValues.pdf
                                        })}
                                        toolTip={'Descargar informe técnico en formato pdf'}
                                        width={'5rem'} />
                      </ButtonGroup>
                  }
                  {
                    formValues.docx !== '' &&
                      <ButtonGroup variant={'contained'}>
                        <MyFileUploadButton label={'DOCX'}
                                            solicitudId={instanciaProceso?.solicitud?.id}
                                            subfolder={subfolder}
                                            fileName={filenameDOCXuploaded}
                                            afterUpload={() => {
                                              API.fetchDocx2PDF(instanciaProceso?.solicitud?.id, subfolder, filenameDOCXuploaded).then(result2 => {
                                                if(f.isValid(result2.rutaDocumento)) {
                                                  const arr2 = result2.rutaDocumento.split('/')
                                                  setFormValues({...formValues, docxLded: filenameDOCXuploaded, pdfLded: arr2[arr2.length-1],})
                                                } else {
                                                  setFormValues({...formValues, docxLded: filenameDOCXuploaded, pdfLded: '',})
                                                }
                                              })
                                            }}
                                            toolTip={'Subir docx actualizado del informe técnico '}
                                            width={'5rem'}
                                            disabled={formValues.firmado || formValues.firmando} />
                      </ButtonGroup>
                  }
                  {
                    formValues.docx !== '' &&
                    <>
                      <MyFileUploadButton label={'PDF (FIRMADO)'}
                                          solicitudId={instanciaProceso?.solicitud?.id}
                                          subfolder={subfolder}
                                          fileName={filenamePDFsigned}
                                          afterUpload={() => setFormValues({...formValues, pdfSigned: filenamePDFsigned, firmado: true, firmando: false})}
                                          toolTip={'Subir documento firmado en formato pdf'}
                                          width={'9rem'}
                                          disabled={formValues.firmado || formValues.firmando || (formValues.docxLded === '')} />
                      {!formValues.firmando &&
                        <MyButtonBacan3 label={'FIRMAR'}
                                        icon={FileDownloadIcon}
                                        onClick={()=> {
                                          API.firmarPdf(instanciaTarea.id, subfolder, filenamePDF).then((res)=> {
                                            if(res?.length > 0) {
                                              const pdfSigned = res[0].rutaDocumento?.split('/')?.at(3)
                                              const firmaTareaId = res[0].firmaTareaId
                                              setFormValues({...formValues, firmando:true, firmado:false, pdfSigned, firmaTareaId})
                                            }
                                          })
                                        }}
                                        disabled={formValues.firmado || (formValues.docxLded === '')}
                                        toolTip={'firmar electronicamente el documento'}
                                        width={'9rem'} />
                      }
                      {
                        formValues.firmado && <MyButtonBacan3 label={''}
                                                                       icon={FileDownloadIcon}
                                                                       onClick={() => API.fetchDownloadPDF({
                                                                         solicitudId: instanciaProceso?.solicitud?.id,
                                                                         subfolder,
                                                                         filename: filenamePDFsigned,
                                                                       })}
                                                                       toolTip={'Descarga informe técnico firmado'}
                                                                       width={'3rem'} />
                      }
                    </>
                  }
                </Stack>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="2">
            <P0102PayloadRO payload={myPayload} />
          </TabPanel>
          <TabPanel value="3">
            <P0102Expediente payload={payload}/>
          </TabPanel>
          {
            f.isValid(payload?.solicitudOriginal?.payloadSolicitud) ?
              <TabPanel value="4">
                <P0102PayloadRO payload={payload.solicitudOriginal.payloadSolicitud}/>
              </TabPanel> : null
          }
          {
            f.isValid(payload?.solicitudOriginal?.payloadSolicitud) ?
              <TabPanel value="5">
                <P0102Expediente payload={payload.solicitudOriginal.payloadSolicitud}/>
              </TabPanel> : null
          }
        </TabContext>
        <CssBaseline/>
        <AppBar position='fixed' color='primary' elevation={0} sx={accordeonBox.bottomBar} >
          <Toolbar>
            <Grid container sx={{p:0, mt:'-1rem'}}>
              <Grid item xs={12} >
                <Stack direction={'row'} spacing={1} justifyContent="space-between" alignItems='center' sx={{p:'0 2rem 0 3rem'}}>
                  <MyGobackButton onGoback={() => dispatch(handleClear())} />
                  <MySaveButton onSave={getOnSave(setCounter)}
                                disabled={counter <= 0 || formValues.firmando} />
                  <MySendButton onSend={() => {
                                          const metadata = JSON.stringify({
                                            "solicitudId":`${instanciaProceso?.solicitud?.id}`,
                                            ...toBPM
                                          })
                                          dispatch(handCompletarTareaCoordinador(instanciaProceso.id, instanciaTarea, perfilUsuario.id, metadata))
                                       }}
                                label={'enviar'}
                                myTip={'Enviar Informe Técnico y continuar con proceso'}
                                disabled={counter > 0 || formValues.pdfSigned === '' || !formValues.firmado} />
                </Stack>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </Box>
    </>
  } else {
    return null
  }
}
