import React from 'react'
import {
  DataGrid,
  gridClasses,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton
} from '@mui/x-data-grid'
import {Box, Stack} from '@mui/material'
import RobotoCondensedRegular from "../styles/MyFonts/Roboto_Condensed/RobotoCondensed-Regular.ttf";
import Typography from "@mui/material/Typography";
import {useDispatch} from "react-redux";
import {
  fetchInstanciaTareaInstanciaProceso,
  handleSetBandeja
} from 'src/features/App/sliceApp'
import {f} from "../commons";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
    </GridToolbarContainer>
  );
}

export const MyTableBandeja = ({rows})  => {
  const dispatch = useDispatch()
  return (
    <Box sx={{ pb: '24px', height:'auto', width: '100%', p:'24px 0 0 0' }}>
      <Stack direction="column" spacing={1}>
        <DataGrid rows={rows}
                  columns={columns(dispatch,fetchInstanciaTareaInstanciaProceso,handleSetBandeja)}
                  autoHeight={true}
                  rowHeight={28}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  headerHeight={40}
                  getRowHeight={() => 'auto'}
                  components={{
                    Toolbar: CustomToolbar,
                  }}
                  sx={{
                    borderRadius: 0,
                    '& .MuiDataGrid-columnHeaders': {
                      backgroundColor: "rgba(244,244,244,0.4)",
                      color: "#aeaeae",
                      fontSize: '0.8rem',
                      fontfamily: RobotoCondensedRegular,
                      fontweight: 'lighter',
                    },
                    '& .MuiDataGrid-virtualScrollerRenderZone': {
                      '& .MuiDataGrid-row': {
                        backgroundColor: 'rgba(255, 255, 255, .7)',
                        fontSize: '0.8rem',
                        fontfamily: RobotoCondensedRegular,
                        fontweight: 'lighter',
                        color: "#888888",
                      },
                      '& .Mui-selected': {
                        color: "#000000",
                      },
                      [`& .${gridClasses.cell}`]: {
                        py: 1,
                      },
                    },
                  }}
                  experimentalFeatures={{ newEditingApi: true }} />
      </Stack>
    </Box>
  )
}

const columns  = (dispatch,fetchInstanciaTareaInstanciaProceso,handleSetBandeja) => [
  { field: 'id',   headerName: 'id', width: 24, editable: false, hide: true },
  { field: 'instanciaProcesoNombreProceso', headerName: 'Flujo de proceso', editable: false, width: 240},
  { field: 'nombreUsuario', headerName: 'Emitido por', editable: false, width: 120 },
  { field: 'origen', headerName: 'Origen', editable: false, width: 120 },
  { field: 'descripcionPerfil', headerName: 'Rol', editable: false, width: 180 },
  {
    // field: "nombreTarea",
    headerName: "Tarea",
    width: 200,
    rditablle: false,
    renderCell: (params) => (
      <Typography style={{fontWeight:'bold', cursor: 'pointer'}}
        onClick={() => {
        dispatch(fetchInstanciaTareaInstanciaProceso(params.id))
        dispatch(handleSetBandeja('entrada'))
      }}>{params.row.nombreTarea}</Typography>
    )
  },
  { field: 'fecha', headerName: 'Fecha', editable: false, width: 180 },
  { field: 'instanciaProcesoSolicitudNumeroSolicitud', headerName: 'No. Solicitud', editable: false, width: 180 },
  { field: 'instanciaProcesoSolicitudNombreProyecto',headerName: 'Nombre Proyecto', editable: false, width: 240 },
]
