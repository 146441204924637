import React, {useEffect, useRef, useState} from 'react'
import {AppBar, Box, ButtonGroup, CssBaseline, Grid, Stack, Tab} from '@mui/material'
import {add, format, parse} from 'date-fns'
import {es} from 'date-fns/locale'
import {accordeonBox, dialog} from 'src/styles/styles'
import {useDispatch, useSelector} from 'react-redux'
import API from "src/features/App/API";
import {handCompletarTareaAndSalir, handleClear, handleSaveSolicitud} from "src/features/App/sliceApp";
import {MySendButton} from 'src/components/MySendButton'
import {MySubtitle} from 'src/components/MySubtitle'
import {Solicitante} from 'src/features/P01Solicitud/subcomponents/Solicitante'
import GLOBALS, {ENV} from 'src/features/App/globals'
import {SectionTitle} from "../../components/SectionTitle";
import {MyReadOnlyTextField} from "../../components/MyReadOnlyTextField";
import {AccountCircle} from "@mui/icons-material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {MyButtonBacan} from "../../components/MyButtonBacan";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import Toolbar from "@mui/material/Toolbar";
import {MyGobackButton, MySaveButton} from "../../components/MyCommonButtons";
import {MyTextField} from "../../components/MyTextField";
import {MyDatePicker} from "../../components/MyDatePicker";
import ApprovalIcon from '@mui/icons-material/Approval';
import {TabContext, TabList, TabPanel} from "@mui/lab"
// import {P0102PayloadRO} from "./P0102PayloadRO";
import {MySwitch} from "../../components/MySwitch";
import {MyButtonBacan3} from "../../components/MyButtonBacan3";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {MyTagged2Docx} from "../../components/MyTagged2Docx";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {MyFileUploadButton} from "../../components/MyFileUploadButton";
import {f} from "../../commons";
import P0102API from "../P01Solicitud/API";
import {PageOrientation} from "docx";
// import {WF03Expediente} from "./subcomponents/P03Expediente";
import {WF03Expediente} from "./subcomponents/P03Expediente";
import {WF03SolicitudRO} from "./P03SolicitudRO";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({id}) => {
  const section = 'CertificadoInternacional'
  const dirName = 'certificado-internacional'
  const inputRef = useRef()
  const dispatch = useDispatch()
  const instanciaTarea = useSelector(state => state.app.instanciaTarea)
  const perfilUsuario = useSelector(state => state.app.perfilUsuario)
  const bandeja = useSelector(state => state.app.bandeja)
  const instanciaProceso = useSelector(state => state.app.instanciaProceso)
  const solicitud = {...instanciaProceso.solicitud}
  const payload = !!solicitud.payload?JSON.parse(solicitud.payload):{}
  const slct = {...solicitud, payload : JSON.parse(solicitud.payload)}
  const today = format(new Date(), 'dd-MMMM-yyyy', {locale: es})
  const funcionarios = useSelector(state => state.app.funcionarios)
  const emptyPayload = {
    fecha:           today,
    numero:          solicitud.numeroSolicitud,
    identificador:   '',
    docx:            '',
    pdf:             '',
    docxLded:        '',
    pdfLded:         '',
    irccpdf:         '',
    absId:           '',
  }
  const [formValues, setFormValues] = useState({...emptyPayload, ...payload[section]})
  const handleChange = (e) => API.handleChange(e, bandeja, setFormValues, formValues)
  const dResolucion = parse(today,'dd-MMMM-yyyy', new Date(), {locale: es})
  const plazo = Number(slct.payload.Propuesta.plazo)
  const dPlazo = add(dResolucion, {months: plazo})
  const [counter, setCounter] = useState(-1)  // updating counter
  useEffect(() => {
    setCounter(counter + 1)
  }, [formValues])
  const [myTab, setMytab] = useState('1');
  const handleChangeTab = (event, newTab) => { setMytab(newTab); }
  const nombreOrganizacion = 'Senescyt'
  const resolucionSubfolder = `resolucion-${nombreOrganizacion}`
  const resolucionFilename = `resolucion-${formValues.numero}-${nombreOrganizacion}`
  const contratoSubfolder = `contrato-${nombreOrganizacion}`
  const contratoFilename = `contrato-${formValues.numero}-${nombreOrganizacion}`
  const contratoFilenamePDF = `${contratoFilename}.pdf`
  const filenamePDFuploaded = `${contratoFilename}-upld.pdf`
  const resolucionFilenamePDFsigned = `${resolucionFilename}-firmado.pdf`
  const contratoFilenamePDFsigned = `${contratoFilename}-firmado.pdf`
  const irccSubfolder = `ircc-${nombreOrganizacion}`
  const filenameDOCXIRCC = 'IRCC.docx'
  const filenamePDFIRCC = 'IRCC.pdf'
  const filenameDOCXIRCCuploaded = 'IRCC-upld.docx'
  const filenamePDFIRCCsigned = 'IRCC-signed.pdf'
  const codigoAutorizador = 1139
  const codigoCoordinador = 164
  const autorizador = funcionarios.filter(it => it.idPerfil === codigoAutorizador)[0]
  const coordinador = funcionarios.filter(it => it.idPerfil === codigoCoordinador)[0]
  const contratoPdf = (payload?.ElaborarContrato?.pdfLded !== '')?payload?.ElaborarContrato?.pdfLded:payload?.ElaborarContrato?.pdf
  const labelTab2 = ['55000015_Activity_RegistrarCertificadoInternacionalWF0102'].includes(id)?'Solicitud':'Solicitud Modificatoria'
  const labelTab3 = ['55000015_Activity_RegistrarCertificadoInternacionalWF0102'].includes(id)?'Expediente':'Expediente'
  if(!!instanciaProceso.id && !!instanciaTarea.id) {
    const contrato = payload?.INFO?.docs?.d09Negociacion.findIndex(x => x.idx === 'REVCONTRATO')
    return <>
      <Box sx={{...accordeonBox.container, pt:'2rem', height: '100%'}}>
        <Grid sx={{...accordeonBox.container, pt:'2rem', height: '100%'}}>
          <AppBar position='fixed'
                  color='primary'
                  elevation={0}
                  sx={{top:'64px', bottom:'auto', margin: 0, padding:0, backgroundColor:'rgba(148, 193, 32, 0.8)'}} >
            <SectionTitle title={'Registro de certificado internacional de investigación'} />
          </AppBar>
          <TabContext value={myTab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChangeTab}
                       aria-label="lab API tabs example">
                <Tab label="Formulario" value="1" />
                <Tab label={labelTab2} value="2" />
                <Tab label={labelTab3} value="3" />
                {
                  f.isValid(payload?.solicitudOriginal?.payloadSolicitud) ?
                    <Tab label={'Solicitud original'} value="4"/> : null
                }
                {
                  f.isValid(payload?.solicitudOriginal?.payloadSolicitud) ?
                    <Tab label={'Expediente original'} value="5"/> : null
                }
              </TabList>
            </Box>
            <TabPanel value="1">
              <Grid container spacing={1} sx={accordeonBox.container2}>
                <Solicitante solicitud={{solicitud: {payload}}} displayContact={false} />
                <Grid item xs={12} sx={dialog.titleContainer}>
                  <MySubtitle subtitle={'Contrato'} />
                </Grid>
                <Grid item xs={3} style={{padding:'0 24px 0 0.5rem'}} >
                  <MyReadOnlyTextField id='identificador'
                                       label={'Identificador'}
                                       value={solicitud?.numeroSolicitud}
                                       icon={<AccountCircle sx={{fontSize: '14px', color: 'silver', mr: '0.8rem'}}/>}/>
                </Grid>
                <Grid item xs={3} style={{padding:'0 24px 0 0.5rem'}} >
                  <MyReadOnlyTextField id='fecha'
                                       label={'Fecha de emisión'}
                                       value={format(dResolucion, 'dd-MMM-yyyy', {locale: es})}
                                       icon={<CalendarMonthIcon sx={{fontSize: '14px', color: 'silver', mr: '0.8rem'}} />} />
                </Grid>
                <Grid item xs={3} style={{padding:'0 24px 0 0.5rem'}} >
                  <MyReadOnlyTextField id='plazo'
                                       label={'Vigencia'}
                                       value={`${plazo} meses`}
                                       icon={<CalendarMonthIcon sx={{fontSize: '14px', color: 'silver', mr: '0.8rem'}} />} />
                </Grid>
                <Grid item xs={3} style={{padding:'0 0 0 0.5rem'}} >
                  <Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{p:'0 0 0 0'}} >
                    <MyReadOnlyTextField id='fechaCaducidad'
                                         label={'Fecha de caducidad'}
                                         value={format(dPlazo, 'dd-MMM-yyyy', {locale: es})}
                                         icon={<CalendarMonthIcon sx={{fontSize: '14px', color: 'silver', mr: '0.8rem'}} />} />
                  </Stack>
                </Grid>
                <Grid item xs={3} style={{padding:'1rem 24px 0 0.5rem'}} >
                  <MyReadOnlyTextField id={'idRegistro'}
                                       label={'Número de contrato'}
                                       value={slct.payload.P03RegistrarContrato.idRegistro}
                                       icon={<ApprovalIcon sx={{fontSize: '14px', color: 'silver', mr: '0.8rem'}} />}/>
                </Grid>
                <Grid item xs={3} style={{padding:'1rem 24px 0 0.5rem'}} >
                  <MyReadOnlyTextField id={'fecha'}
                                       label={'Fecha de firma de contrato'}
                                       value={today}
                                       icon={<CalendarMonthIcon sx={{fontSize: '14px', color: 'silver', mr: '0.8rem'}} />}/>
                </Grid>
                <Grid item xs={3} style={{padding:'1rem 24px 0 0.5rem'}} >
                </Grid>
                <Grid item xs={3} style={{padding:'2rem 24px 0 0.5rem'}} >
                  <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} >
                    <MyButtonBacan3
                      onClick={() => API.fetchDownloadPDF({
                        solicitudId: payload?.INFO?.docs?.d09Negociacion[contrato]?.solicitudId,
                        subfolder: payload?.INFO?.docs?.d09Negociacion[contrato]?.subFolder,
                        filename: payload.INFO.docs?.d09Negociacion[contrato]?.archivo,
                      })}
                      label={'CONTRATO'}
                      width={'10rem'} />
                  </Stack>
                </Grid>
                <Grid item xs={12} sx={dialog.titleContainer}>
                  <MySubtitle subtitle={'Certificado internacional de investigación'} />
                </Grid>
                <Grid item xs={12} >
                  <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} >
                    <Box sx={{width:'25%'}} >
                      <MySwitch id={'cuentaConIRCC'}
                                label={'¿Este contrato cuenta con IRCC?'}
                                fullWidth={true}
                                formValues={payload?.P03RegistrarContrato}
                                canEdit={false} />
                    </Box>
                    {payload?.P03RegistrarContrato.cuentaConIRCC &&
                      <>
                        <Box sx={{width: '25%', m:'1rem 0 0 0'}}>
                          <MyTextField id={'absId'}
                                       label={'ABS-CH Unique Identifier (UID)'}
                                       formValues={formValues}
                                       setFormValues={setFormValues}
                                       handleChange={handleChange}
                                       canEdit={true}/>
                        </Box>
                        <Box sx={{width:'25%'}} >
                          <MyDatePicker id={'fecha'}
                                        canEdit={true}
                                        label={'Fecha'}
                                        formValues={formValues}
                                        setFormValues={setFormValues} />
                        </Box>
                      </>
                    }
                  </Stack>
                </Grid>
                <Grid item xs={12} style={{padding:'0 24px 0 0.5rem'}} >
                  <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1} style={{padding:'0 24px 0 8rem'}}>
                    {
                      formValues.docx !== '' &&
                      <ButtonGroup variant={'contained'}>
                        <MyButtonBacan3 label={''}
                                        width={'3rem'}
                                        onClick={() => {
                                          setFormValues({
                                            ...formValues,
                                            docx:            '',
                                            pdf:             '',
                                            docxLded:        '',
                                            pdfLded:         '',
                                            irccpdf:         '',
                                          })
                                        }}
                                        icon={RestartAltIcon}/>
                      </ButtonGroup>
                    }
                    {
                      formValues.docx === '' &&
                      <ButtonGroup variant={'contained'}>
                        <MyButtonBacan3 label={'Generar Informe'}
                                        width={'11rem'}
                                        onClick={() => {
                                          const doc = MyTagged2Docx(plantilla, nombreOrganizacion, P0102API.mapping({
                                            solicitudId: instanciaProceso?.solicitud?.numeroSolicitud,
                                            payload,
                                            autorizador,
                                            coordinador,
                                            perfilUsuario,
                                            nombreOrganizacion
                                          }), PageOrientation.PORTRAIT, instanciaProceso?.solicitud?.nombreProyecto)
                                          API.genDocxAnPdf({
                                            solicitudId: instanciaProceso?.solicitud?.id,
                                            document: doc,
                                            formValues,
                                            setFormValues,
                                            subfolder: irccSubfolder,
                                            filename: filenameDOCXIRCC,
                                            nombreOrganizacion,
                                          })
                                        }}
                                        icon={MiscellaneousServicesIcon} />
                      </ButtonGroup>
                    }
                    {
                      formValues.docx !== '' &&
                      <>
                        <MyButtonBacan3 label={'DOCX'}
                                        icon={FileDownloadIcon}
                                        onClick={() => API.fetchDownloadDOCX({
                                          solicitudId: instanciaProceso?.solicitud?.id,
                                          subfolder:irccSubfolder,
                                          filename: (formValues.docxLded !== '') ? formValues.docxLded : formValues.docx,
                                        })}
                                        toolTip={'Descargar IRCC en formato docx'}
                                        width={'5rem'} />
                        <MyButtonBacan3 label={'PDF'}
                                        icon={FileDownloadIcon}
                                        onClick={() => API.fetchDownloadPDF({
                                          solicitudId: instanciaProceso?.solicitud?.id,
                                          subfolder:irccSubfolder,
                                          filename: (formValues.pdfLded !== '') ? formValues.pdfLded : formValues.pdf
                                        })}
                                        toolTip={'Descargar IRCC en formato pdf'}
                                        width={'5rem'} />
                      </>
                    }
                    {/*{*/}
                    {/*  formValues.docx !== '' &&*/}
                    {/*  <ButtonGroup variant={'contained'}>*/}
                    {/*    <MyFileUploadButton label={'DOCX'}*/}
                    {/*                        solicitudId={instanciaProceso?.solicitud?.id}*/}
                    {/*                        subfolder={irccSubfolder}*/}
                    {/*                        fileName={filenameDOCXIRCCuploaded}*/}
                    {/*                        afterUpload={() => {*/}
                    {/*                          API.fetchDocx2PDF(instanciaProceso?.solicitud?.id, irccSubfolder, filenameDOCXIRCCuploaded).then(result2 => {*/}
                    {/*                            if(f.isValid(result2.rutaDocumento)) {*/}
                    {/*                              const arr2 = result2.rutaDocumento.split('/')*/}
                    {/*                              setFormValues({...formValues, docxLded: filenameDOCXIRCCuploaded, pdfLded: arr2[arr2.length-1],})*/}
                    {/*                            } else {*/}
                    {/*                              setFormValues({...formValues, docxLded: filenameDOCXIRCCuploaded, pdfLded: '',})*/}
                    {/*                            }*/}
                    {/*                          })*/}
                    {/*                        }}*/}
                    {/*                        toolTip={'Subir docx actualizado del IRCC'}*/}
                    {/*                        width={'5rem'} />*/}
                    {/*  </ButtonGroup>*/}
                    {/*}*/}
                    {
                      formValues.docx !== '' &&
                      <>
                        <MyFileUploadButton label={'SUBIR IRCC'}
                                            solicitudId={instanciaProceso?.solicitud?.id}
                                            subfolder={irccSubfolder}
                                            fileName={filenamePDFIRCCsigned}
                                            afterUpload={() => {
                                              setFormValues({...formValues, irccpdf: filenamePDFIRCCsigned})
                                            }}
                                            toolTip={'Subir documento firmado en formato pdf'}
                                            width={'9rem'} />
                        {
                          formValues.irccpdf !== '' && <MyButtonBacan3 label={''}
                                                                       icon={FileDownloadIcon}
                                                                       onClick={() => API.fetchDownloadPDF({
                                                                         solicitudId: instanciaProceso?.solicitud?.id,
                                                                         subfolder: irccSubfolder,
                                                                         filename: filenamePDFIRCCsigned,
                                                                       })}
                                                                       toolTip={'Descarga informe técnico firmado'}
                                                                       width={'3rem'} />
                        }
                      </>
                    }
                  </Stack>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value="2">
              <WF03SolicitudRO payload={payload}/>
            </TabPanel>
            <TabPanel value="3">
              <WF03Expediente payload={payload} />
            </TabPanel>
            {
              f.isValid(payload?.solicitudOriginal?.payloadSolicitud) ?
                <TabPanel value="4">
                  <WF03SolicitudRO payload={payload.solicitudOriginal.payloadSolicitud}/>
                </TabPanel> : null
            }
            {
              f.isValid(payload?.solicitudOriginal?.payloadSolicitud) ?
                <TabPanel value="5">
                  <WF03Expediente payload={payload.solicitudOriginal.payloadSolicitud}/>
                </TabPanel> : null
            }
          </TabContext>
        </Grid>
        <CssBaseline/>
        <AppBar position='fixed' color='primary' elevation={0} sx={accordeonBox.bottomBar} >
          <Toolbar>
            <Grid container sx={{p:0, mt:'-1rem'}}>
              <Grid item xs={12} >
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} >
                  <MyGobackButton onGoback={() => dispatch(handleClear())} />
                  <MySaveButton onSave={() => {
                                  payload[section]=formValues
                                  dispatch(handleSaveSolicitud(
                                    instanciaProceso?.id,
                                    payload,
                                    () => setCounter(0)
                                  ))
                                }}
                                disabled={counter <= 0 } />
                  <MySendButton onSend={() => {
                                  const metadata = JSON.stringify({
                                    "solicitudId": `${instanciaProceso?.solicitud?.id}`,
                                    "icrr": formValues.cuentaConIRCC,
                                  })
                                  dispatch(handCompletarTareaAndSalir(instanciaProceso.id, instanciaTarea, perfilUsuario.id, metadata))
                                  if(instanciaTarea.tareaCodigoTarea ==='55000017_Activity_RegistrarContratoWF0405') {
                                    API.fetchSuspenderSolicitud(payload?.solicitudOriginal?.idSolicitud, true).then(res => {
                                      console.log('03 :: >> ', res, )
                                    })
                                  }
                                }}
                                disabled={counter > 0 || formValues.irccpdf === ''}
                                label={'terminar'} />
                </Stack>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </Box>
    </>
  }
  else {
    return null
  }
}

const plantilla = ''
  + '${TITULO1}IRCC'
  + '${SALTO}${TITULO2}Información General (General Information)'
  + '${SALTO}${VAR2}País (Country)${:}Ecuador'
  + '${SALTO}${PARRAFO}Enlaces a otros certificados de cumplimiento (IRCC) reconocidos internacionalmente relacionados con este permiso (Links to other internationally recognized certificate (s) of compliance (IRCC) that relate(s) to this permit)'
  + '${SALTO}${PARRAFO}'
  + '${SALTO}${TITULO2}Autoridad emisora (Issuing authority)'
  + '${SALTO}${VAR2}Autoridad nacional competente (CNA) responsable de emitir este permiso o su equivalente (Competent national authority (CNA) responsible for issuing this permit or its equivalent)'
  + '${:}Secretaría de Educación Superior, Ciencia, Tecnología e Innovación'
  + '${SALTO}${TITULO2}Detalles del permiso o su equivalente (Details of the permit or its equivalent)'
  + '${SALTO}${VAR2}Número de referencia del permiso o su equivalente (Reference number of the permit or its equivalent)'
  + '${:}<<solicitante.identificador>>'
  + '${SALTO}${PARRAFO}Identificadores o referencias nacionales adicionales (Additional national references or identifiers) (información opcional)'
  + '${SALTO}${VAR2}Proyecto:${:}<<solicitud.nombreProyecto>>'
  + '${SALTO}${VAR2}Institución Patrocinadora:${:}<<patrocinador>>'
  + '${SALTO}${VAR2}Institución Nacional de Apoyo:${:}<<solicitud.institucionNacionalApoyo>>'
  + '${SALTO}${VAR2}Legítimos poseedores:${:}<<solicitud.legitimosPoseedores>>'
  + '${SALTO}${VAR2}Fecha de expedición del permiso o su equivalente (Date of issuance of the permit or its equivalent)'
  + '${:}<<solicitud.fecha>>'
  + '${SALTO}${VAR2}Fecha de vencimiento del permiso o su equivalente (Date of expiry of the permit or its equivalent)'
  + '${:}<<autorizacion.fechaHasta>>'
  + '${SALTO}${TITULO2}Información sobre el consentimiento fundamentado previo (PIC) (Prior Informed Consent (PIC) Information)'
  + '${SALTO}${VAR2}Esta información es confidencial (This information is confidential)'
  + '${:}SI/YES'
  + '${SALTO}${VAR2}El proveedor (The provider)'
  + '${:}Secretaría de Educación Superior, Ciencia, Tecnología e Innovación (Senescyt)'
  + '${SALTO}${VAR2}Confirmación de que se obtuvo u otorgó el consentimiento fundamentado previo (CIP)${:}(Confirmation that prior informed consent (PIC) obtained or granted)'
  + '${SALTO}${ACCESO.CONOCIMIENTO}<<solicitud.accesoConocimientoTradicional>>'
  + '${SALTO}${VAR2}Información adicional sobre el consentimiento fundamentado previo (PIC) (Additional information about the prior informed consent (PIC))'
  + '${:}Nota: Esta actividad se deberá realizar por fuera del sistema (dato fijo)'
  + '${SALTO}${VAR2}Información adicional sobre el consentimiento informado previo (PIC) – Documentos (Additional information about the prior informed consent (PIC) – Documents)'
  + '${:}No aplica'
  + '${SALTO}${PARRAFO}Nota: Se considera que el PIC y el contrato de acceso a conocimientos tradicionales son documentos confidenciales'
  + '${SALTO}${VAR2}Esta información es confidencial (This information is confidential)'
  + '${SALTO}${:}SI/YES'
  + '${SALTO}${VAR2}Persona o entidad a quien se le otorgó el consentimiento fundamentado previo (PIC) (Person or entity to whom prior informed consent (PIC) was granted)'
  + '${:}Solicitar información al Senadi de ser el caso (dato fijo)'
  + '${SALTO}${PARRAFO}Nota: Esta actividad se deberá realizar por fuera del sistema (dato fijo)'
  + '${SALTO}${TITULO2}Información sobre términos mutuamente acordados (MAT) (Mutually Agreed Terms (MAT) Information)'
  + '${SALTO}${VAR2}Confirmación de términos mutuamente acordados (MAT) – Información (Confirmation that mutually agreed terms (MAT) – Information)'
  + '${:}SI'
  + '${SALTO}${PARRAFO}Nota: Si no se han establecido términos mutuamente acordados (MAT), este registro no se puede publicar en la Cámara de Compensación de ABS y, por lo tanto, no se puede emitir un certificado de cumplimiento reconocido internacionalmente'
  + '${SALTO}${PARRAFO}Información adicional sobre los términos mutuamente acordados (MAT) – Información (Additional information about the mutually agreed terms (MAT) – Information)'
  + '${SALTO}${VAR2}Título del contrato${:}<<solicitud.nombreProyecto>>'
  + '${SALTO}${PARRAFO}Comparecientes:'
  + '${SALTO}${PARRAFO}<<sistema.rolAutorizadorPrincipalSenescyt>>'
  + '${SALTO}${PARRAFO}<<solicitud.representanteLegalPatrocinadora>>'
  + '${SALTO}${VAR2}Objeto del contrato${:}<<solicitud.objetivoProyecto>>'
  + '${SALTO}${PARRAFO}Información adicional sobre los términos mutuamente acordados (MAT) – Documentos (Additional information about the mutually agreed terms (MAT) – Documents)'
  + '${SALTO}${VAR2}Manejo confidencial de solicitud${:}[La solicitud requiere manejo confidencial SI/NO]'
  + '${SALTO}${TITULO2}Materia – materia de recursos genéticos (Subject – matter of genetic resource)'
  + '${SALTO}${VAR2}Esta información es confidencial (This information is confidential)'
  + '${:}YES/SI'
  + '${SALTO}${PARRAFO}Objeto del recurso genético cubierto por el permiso o su equivalente (Subject – matter of genetic resource covered by the permit or its equivalent)'
  + '${SALTO}${VAR2}Título del proyecto${:}<<solicitud.nombreProyecto>>'
  + '${SALTO}${VAR2}Objetivo general${:}<<solicitud.objetivoProyecto>>'
  + '${SALTO}${PARRAFO}Objetivos específicos:'
  + '${SALTO}${LISTA.SIMPLE.NUMERADA}<<objetivos>>'
  + '${SALTO}${TITULO2}Ubicación geográfica del recurso/Geographic location of resources'
  + '${SALTO}${VAR2}ProvinciaS${:}<<ubicacion.listaProvincias>>'
  + '${SALTO}${VAR2}Áreas Protegida${:}<<ubicacion.listaAreasProtegidas>>'
  + '${SALTO}${VAR2}Bosques Protectores${:}<<ubicacion.listaBosquesProtectores>>'
  + '${SALTO}Nivel Taxonómico/Taxonomic Level'
  + '${SALTO}${PARRAFO.VACIO}'
  + '${SALTO}${RECURSOS.INSITU}<<recursos.tablaRecursosInSitu>>'
  + '${SALTO}${PARRAFO.VACIO}'
  + '${SALTO}'
  + '${SALTO}${RECURSOS.EXSITU}<<recursos.tablaRecursosExSitu>>'
  + '${SALTO}${TITULO2}Información adicional sobre los recursos genéticos (Additional subject matter or genetic resource information)'
  + '${SALTO}${PARRAFO}Datos de la muestra (Specimen data)'
  + '${SALTO}${PARRAFO}Opcional incluir enlace de espécimen objeto de la investigación'
  + '${SALTO}${PARRAFO}Taxonomía (Taxonomy)'
  + '${SALTO}${PARRAFO}Opcional incluir enlace de registro en una base de datos de taxonomía externa, GBIF, catálogo de la vida, etc.'
  + '${SALTO}${PARRAFO}Coordenadas geográficas (Geographic coordinates)'
  + '${SALTO}${PARRAFO}Opcional incluir archivo de coordenadas geográficas – solo archivos en geojson'
  + '${SALTO}${PARRAFO}Palabras clave para describir los recursos genéticos cubiertos por el permiso o su equivalente (Keywords to describe the subject-matter or genetic resource(s) covered by the permit or its equivalent)'
  + '${SALTO}${PARRAFO}Seleccionar palabras clave relevantes que puedan ayudar a describir el tema o los recursos genéticos cubiertos por el permiso o su equivalente.'
  + '${SALTO}${TITULO2}Información sobre la utilización de los recursos genéticos (Information on the utilization of the genetic resource(s))'
  + '${SALTO}${VAR2}Esta información es confidencial (This information is confidential)'
  + '${:}SI/YES'
  + '${SALTO}${VAR2}Indicar si el permiso o su equivalente cubre el uso comercial y/o no comercial (Indicate if the permit or its equivalent covers commercial and/or non-commercial use)'
  + '${SALTO}${:}No comercial/Non commercial'
  + '${SALTO}${VAR2}Información adicional sobre los usos especificados cubiertos por el permiso o su equivalente o restricciones de uso (Additional information about the specified uses covered by the permit or its equivalent or use restrictions)${_}...'
  + '${SALTO}${VAR2}Condiciones para la transferencia a terceros (Conditions for third party transfer)${:}'
  // + '${SALTO}${PARRAFO}Obligación del solicitante que indica “Celebrar con terceros, contratos, convenios y acuerdos que sean necesarios para el desarrollo de la investigación, previa revisión de la Senescyt”, extraído del contrato marco de acceso (extraído del sistema)'
  // + '${SALTO}${PARRAFO}<<autorizacion.contenidoObligaciones>>'
  + '${SALTO}${TITULO2}Documentación (Documentation)'
  + '${SALTO}${PARRAFO}Copia del permiso o su equivalente, u otro documento relevante de acceso abierto (Copy of the permit or its equivalent, or other relevant open-access document)'
  + '${SALTO}${PARRAFO}Adjunto/Attachment'
  + '${SALTO}${PARRAFO}Breve descripción de los enlaces y/o archivos adjuntos (Short description of the attached link(s) and/or file(s))'
  + '${SALTO}${PARRAFO}Título del contrato de acceso extraído del sistema siempre y cuando se haya subido un documento en el campo anterior'
  + '${SALTO}${TITULO2}Información adicional (Additional Information)'
  + '${SALTO}${PARRAFO}Información adicional (Additional Information)'
  + '${SALTO}${PARRAFO}Adicional: incluir cualquier otra información relevante que no se haya abordado en otra parte del registro.'
  + '${SALTO}${PARRAFO}Other relevant website addresses and/or attached documents'
  + '${SALTO}${PARRAFO}Adicional incluir otras direcciones de sitios web relevantes y/o documentos adjuntos'
  + '${SALTO}${TITULO2}Notas/Notes'
  + '${SALTO}${PARRAFO}Adicional incluir una nota relevante del registro.'
