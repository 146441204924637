import {Box, CssBaseline, Grid, Stack} from "@mui/material";
import {accordeonBox, dialog} from "../../styles/styles";
import AppBar from "@mui/material/AppBar";
import {SectionTitle} from "../../components/SectionTitle";
import API from 'src/features/App/API'
// import Toolbar from "@mui/material/Toolbar";
import {MyButtonBacan} from "../../components/MyButtonBacan";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {MySubtitle} from "../../components/MySubtitle";
import {f} from "../../commons";
import {MyButtonBacan3} from "../../components/MyButtonBacan3";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import GLOBALS from "../App/globals";

export const P0102Expediente = ({payload}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [expanded, setExpanded] = React.useState(false)
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }
  const VerPdf = ({id, subfolder, fileName}) => {
    return (
      <MyButtonBacan icon={PictureAsPdfOutlinedIcon}
                     label={'Ver documento'}
                     onClick={ () => {
                       const url = `${GLOBALS.mainUrl}/documentos/descargar?filename=vuv-${id}/${subfolder}/${fileName}`
                       fetch(url)
                         .then((res) => { return res.blob(); })
                         .then((data) => {
                           const datas = new Blob([data], { type: 'application/pdf' })
                           const a = document.createElement("a")
                           a.href = window.URL.createObjectURL(datas)
                           a.target="_blank"
                           a.click()
                         })
                     }} />
    )
  }

  const Doc = ({title, label, toolTip, solicitudId, subfolder, filename, fecha, serial}) => (
    <Grid item xs={12} sx={dialog.titleContainer3} alignItems={'center'}>
      <Stack direction={'row'}>
        <Box sx={{width:'30%'}}><MySubtitle subtitle={title} /></Box>
        <Box sx={{width:'20%'}}><MySubtitle subtitle={serial} /></Box>
        <Box sx={{width:'30%'}}><MySubtitle subtitle={fecha} /></Box>
        <Box sx={{width:'20%'}} justifyContent="flex-end">
          <VerPdf id={solicitudId} subfolder={subfolder} fileName={filename} />
        </Box>
      </Stack>
    </Grid>
  )

  let verDoc = 'VER DOCUMENTO'

  // console.log(':::::: >>>>> ', payload?.EmitirDictamenTecnicoSenadi)

  return (
    <>
      <Box sx={{...accordeonBox.container, pt:'2rem', height: '100%', backgroundImage: 'url(src/style/MySvgIcons)'}}>
        <AppBar position='fixed'
                color='primary'
                elevation={0}
                sx={{top:'64px', bottom:'auto', margin: 0, padding:0, backgroundColor:'rgba(148, 193, 32, 0.8)'}} >
          <SectionTitle title={API.processTitle} />
        </AppBar>
        {f.isValidNotEmpty(payload?.Propuesta?.cartaPatrocidador) ?
          <Doc title={'Carta patrocinador'}
               label={verDoc}
               toolTip={'Descarga carta del patrocinador'}
               solicitudId={payload.solicitudId}
               subfolder={'cartaPatrocidador'}
               filename={payload.Propuesta.cartaPatrocidador}
               fecha={''}
               serial={''} />
          : null
        }
        {f.isValidNotEmpty(payload?.Propuesta?.convenioApoyo) ?
          <Doc title={'Convenio de apoyo'}
               label={verDoc}
               toolTip={'Descarga convenio de apoyo'}
               solicitudId={payload.solicitudId}
               subfolder={'convenioApoyo'}
               filename={payload.Propuesta.convenioApoyo}
               fecha={''}
               serial={''} />
          : null
        }
        {f.isValidNotEmpty(payload?.Propuesta?.repLegalApoyo) ?
          <Doc title={'Representante legal apoyo'}
               label={verDoc}
               toolTip={'Descarga convenio de apoyo'}
               solicitudId={payload.solicitudId}
               subfolder={'repLegalApoyo'}
               filename={payload.Propuesta.repLegalApoyo}
               fecha={''}
               serial={''} />
          : null
        }

        {f.isValidNotEmpty(payload?.Propuesta?.cartaApoyo) ?
          <Doc title={'Carta de apoyo'}
               label={verDoc}
               toolTip={'Descarga carta de apoyo'}
               solicitudId={payload.solicitudId}
               subfolder={'cartaApoyo'}
               filename={payload.Propuesta.cartaApoyo}
               fecha={''}
               serial={''} />
          : null
        }

        {f.isValidNotEmpty(payload?.InformeTecnicoSenescyt?.pdfSigned) ?
          <Doc title={'Informe técnico SENESCYT'}
               label={verDoc}
               toolTip={'Descarga informe técnico firmado'}
               solicitudId={payload.solicitudId}
               subfolder={'informe-tecnico-Senescyt'}
               filename={payload.InformeTecnicoSenescyt.pdfSigned}
               fecha={payload.InformeTecnicoSenescyt.fecha}
               serial={payload.InformeTecnicoSenescyt.serial} />
          : null
        }
        {f.isValidNotEmpty(payload?.InformeTecnicoMaate?.pdfSigned) ?
          <Doc title={'Informe técnico MAATE'}
               label={verDoc}
               toolTip={'Descarga informe técnico firmado'}
               solicitudId={payload.solicitudId}
               subfolder={'informe-tecnico-Maate'}
               filename={payload.InformeTecnicoMaate.pdfSigned}
               fecha={payload.InformeTecnicoMaate.fecha}
               serial={payload.InformeTecnicoMaate.serial}/>
          : null
        }
        {f.isValidNotEmpty(payload?.DictamenTecnicoMaate?.pdfSigned) ?
          <Doc title={'Dictamen técnico MAATE'}
               label={verDoc}
               toolTip={'Descarga dictamen técnico firmado'}
               solicitudId={payload.solicitudId}
               subfolder={'dictamen-tecnico-Maate'}
               filename={payload.DictamenTecnicoMaate.pdfSigned}
               fecha={payload.DictamenTecnicoMaate.fecha}
               serial={payload.DictamenTecnicoMaate.serial} />
          : null
        }
        {f.isValidNotEmpty(payload?.EmitirDictamenTecnicoSenadi?.pdfSigned) ?
          <Doc title={'Dictamen técnico SENADI'}
               label={verDoc}
               toolTip={'Descarga dictamen técnico firmado'}
               solicitudId={payload.solicitudId}
               subfolder={'dictamen-tecnico-Senadi'}
               filename={payload.EmitirDictamenTecnicoSenadi.pdfSigned}
               fecha={payload.EmitirDictamenTecnicoSenadi.fecha}
               serial={payload.EmitirDictamenTecnicoSenadi.serial} />
          : null
        }
        {f.isValidNotEmpty(payload?.Resolucion?.pdfSigned) ?
          <Doc title={'Resolución'}
               label={verDoc}
               toolTip={'Descarga resolución'}
               solicitudId={payload.solicitudId}
               subfolder={'resolucion-Senescyt'}
               filename={payload.Resolucion.pdfSigned}
               fecha={payload.Resolucion.fecha}
               serial={payload.Resolucion.serial} />
          : null
        }
        {f.isValidNotEmpty(payload?.ElaborarPermiso?.pdfSigned) ?
          <Doc title={'Permiso suscrito'}
               label={verDoc}
               toolTip={'Descarga permiso suscrito firmado'}
               solicitudId={payload.solicitudId}
               subfolder={'permiso-Senescyt'}
               filename={payload.ElaborarPermiso.pdfSigned}
               fecha={payload.ElaborarPermiso.fecha}
               serial={payload.ElaborarPermiso.serial} />
          : null
        }
        {(f.isValidNotEmpty(payload?.ElaborarContrato?.pdf) || f.isValidNotEmpty(payload?.ElaborarContrato?.pdfLded)) ?
          <Doc title={'Contrato'}
               label={verDoc}
               toolTip={'Descarga contrato'}
               solicitudId={payload.solicitudId}
               subfolder={'contrato-Senescyt'}
               filename={f.isValidNotEmpty(payload?.ElaborarContrato?.pdfSigned)?payload.ElaborarContrato.pdfSigned:payload?.ElaborarContrato?.pdfLded}
               fecha={payload.ElaborarContrato.fecha}
               serial={payload.ElaborarContrato.serial} />
          : null
        }
        {(f.isValidNotEmpty(payload?.CertificadoInternacional?.irccpdf) || f.isValidNotEmpty(payload?.CertificadoInternacional?.irccpdf)) ?
          <Doc title={'IRCC'}
               label={'IRCC'}
               toolTip={'Descarga IRCC'}
               solicitudId={payload.solicitudId}
               subfolder={'ircc-Senescyt'}
               filename={payload?.CertificadoInternacional?.irccpdf}
               fecha={payload?.CertificadoInternacional?.fecha}
               serial={payload?.RegistrarContrato?.idRegistro} />
          : null
        }
        <CssBaseline />
      </Box>
    </>
  )
}
