import React from 'react'
import {accordeonBox, dialog} from "../../styles/styles";
import {
  Box,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  Link,
  Select,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import {MySubtitle} from "../../components/MySubtitle";
import {MyReadOnlyTextField} from "../../components/MyReadOnlyTextField";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {MyTableRecursos2} from "../../components/MyTableRecursos2";
import BiotechIcon from "@mui/icons-material/Biotech";
import Autorizacion from "../P01Solicitud/subcomponents/Autorizacion";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import RobotoCondensedRegular from "../../styles/MyFonts/Roboto_Condensed/RobotoCondensed-Regular.ttf";
import {MyTableRecolectores} from "../../components/MyTableRecolectores";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import API from "../App/API";
import {MyButtonBacan3} from "../../components/MyButtonBacan3";


export const P07DMRRO = ({payload}) => {
  const solicitud = payload.Solicitud
  const arr = solicitud?.identificador?.split('-')
  const solicitudId = arr[arr.length - 1]

  return (
    <Grid container spacing={1} sx={{...accordeonBox.container2, m: '1rem 0 4rem 0'}}>
      <Grid item xs={12} sx={dialog.titleContainer}>
        <MySubtitle subtitle={'Declaración'}/>
      </Grid>
      <Grid item xs={6} >
        <MyReadOnlyTextField label={'Identificador'}
                             icon={<BorderColorIcon fontSize={'1rem'} sx={{color:'silver', m:'0 0.8rem 0 0'}}/>}
                             value={solicitud.numeroSolicitud} />
      </Grid>
      <Grid item xs={6} >
        <MyReadOnlyTextField label={'Fecha'}
                             icon={<CalendarMonthIcon fontSize={'1rem'} sx={{color:'silver', m:'0 0.8rem 0 0'}}/>}
                             value={solicitud['fecha']} />
      </Grid>
      <Autorizacion solicitud={{numeroSolicitud: payload?.Solicitud?.aprobadaIdentificador, payload: JSON.parse(payload?.Solicitud?.solicitudAprobada)}} />
      <Grid item xs={12}>
        <FormControl>
          <FormLabel id="demo-row-radio-buttons-group-label">
            Recursos de la biodiversidad a ser movilizados
          </FormLabel>
          <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={solicitud.tipo} >
            <FormControlLabel value='IN-SITU' control={<Radio />} label='Recurso a ser recolectado (in situ)?' />
            <FormControlLabel value='EX-SITU' control={<Radio />} label='Recurso depositado previamente (ex situ)' />
            {/*<FormControlLabel value='IN-EX-SITU' control={<Radio />} label='Ambos, in situ y ex situ' />*/}
          </RadioGroup>
        </FormControl>
      </Grid>
      {['IN-SITU', 'IN-EX-SITU'].includes(solicitud.tipo)?
        <>
          <Grid item xs={12} sx={dialog.titleContainer}>
            <MySubtitle subtitle={'Recursos IN SITU'}/>
          </Grid>

          <Grid item xs={12}>
            <MyTableRecursos2 id={'recursosIS'}
                              canEdit={false}
                              formValues={solicitud}
                              mode={'ATM-IS'} />
          </Grid>
          <Grid item xs={12}>
            <MySubtitle subtitle={'Origen de los recursos'} />
          </Grid>
          <Grid item xs={6} >
            <MyReadOnlyTextField id={'provincias'}
                                  label={'Provincias *'}
                                  value={solicitud.provincias}
                                  canEdit={false} />
          </Grid>
          <Grid container sx={{p:'0 0 0 0.5rem'}}>
            <Grid item xs={6} >
              <MyReadOnlyTextField id={'areasProtegidas'}
                                   label={'Áreas protegidas'}
                                   value={solicitud.areasProtegidas}
                                   canEdit={false}  />
            </Grid>
            <Grid item xs={6} >
              <MyReadOnlyTextField id={'bosquesProtectores'}
                                   label={'Bosques Protectores'}
                                   value={solicitud.bosquesProtectores}
                                   canEdit={false} />
            </Grid>
            <Grid item xs={12} >
              <MySubtitle subtitle={'Destino de los recursos'} />
            </Grid>
            <Grid item xs={12} >
              <MyReadOnlyTextField id={'laboratoriosIS'}
                                   label={'Institución en la que se desarrolla la fase de laboratorio'}
                                   value={solicitud.laboratoriosIS}
                                   canEdit={false} />
            </Grid>
            <Grid item xs={4} alignItems="center" sx={{pr:'1rem'}}>
              <MyReadOnlyTextField  id={'provinciaIS'}
                                    label={'Provincia'}
                                    value={solicitud.provinciaIS} />
            </Grid>
            <Grid item xs={4} alignItems="center"  sx={{p:'0 1rem 0 1rem'}}>
              <MyReadOnlyTextField  id={'cantonIS'}
                                    label={'Cantón'}
                                    value={solicitud.cantonIS} />
            </Grid>
            <Grid item xs={4} alignItems="center" sx={{pl:'1rem'}}>
              <MyReadOnlyTextField  id={'parroquiaIS'}
                                    label={'Parroquia'}
                                    value={solicitud.parroquiaIS} />
            </Grid>

            <Grid item xs={12} alignItems="center">
              <MyReadOnlyTextField id={'rutaIS'}
                                   label={'Ruta'}
                                   value={solicitud.rutaIS}  />
            </Grid>
          </Grid>
        </>:null
      }
      {
        (['EX-SITU', 'IN-EX-SITU'].includes(solicitud.tipo)) ?
          <>
            <Grid item xs={12} sx={dialog.titleContainer}>
              <MySubtitle subtitle={'Recursos EX SITU'}/>
            </Grid>
            <Grid item xs={12}>
              <MySubtitle subtitle={'Origen de los recursos'} />
            </Grid>
            <Grid item xs={12}>
              <MyReadOnlyTextField  id={'origenES'}
                         label={'Centro de documentación'}
                         value={solicitud.origenES}
                         icon={<BiotechIcon sx={{fontSize: '14px', color:'silver'}} />}
                         canEdit={false} />
            </Grid>
            <Grid item xs={12}>
              <MyTableRecursos2 id={'recursosES'}
                                canEdit={false}
                                formValues={solicitud}
                                showHolotipo={true}
                                mode={'ATM-ES'} />
            </Grid>
            <Grid item xs={12} >
              <MySubtitle subtitle={'Destino de los recursos'} />
            </Grid>
            <Grid item xs={10} alignItems="center">
              <MyReadOnlyTextField id={'laboratoriosES'}
                                       label={'Institución en la que se desarrolla la fase de laboratorio'}
                                       value={solicitud.laboratoriosES}
                                       icon={<BiotechIcon sx={{fontSize: '14px', color:'silver'}} />}
                                       canEdit={false} />
            </Grid>
            <Grid item xs={4} alignItems="center" sx={{pr:'1rem'}}>
              <MyReadOnlyTextField  id={'provinciaES'}
                                    label={'Provincia'}
                                    value={solicitud.provinciaES} />
            </Grid>
            <Grid item xs={4} alignItems="center"  sx={{p:'0 1rem 0 1rem'}}>
              <MyReadOnlyTextField  id={'cantonES'}
                                    label={'Cantón'}
                                    value={solicitud.cantonES} />
            </Grid>
            <Grid item xs={4} alignItems="center" sx={{pl:'1rem'}}>
              <MyReadOnlyTextField  id={'parroquiaES'}
                                    label={'Parroquia'}
                                    value={solicitud.parroquiaES} />
            </Grid>
            <Grid item xs={12} sx={dialog.titleContainer}>
              <MySubtitle subtitle={'Transporte y responsables'} />
            </Grid>

            <Grid container spacing={2} >
              <Grid item xs={6} >
                <Box sx={{p:'0 1rem 1rem 0.8rem'}}>
                  <MyReadOnlyTextField id={'tipoTransporte'}
                            label={'Tipo de Transporte *'}
                            value={solicitud.tipoTransporte}
                            width={'10rem'} />
                </Box>
              </Grid>
              <Grid item xs={3} >
                <Box sx={{p:'0.9rem 1rem 1rem 1rem'}} >
                  <MyReadOnlyTextField id={'fechaInicio'} label={'Fecha Inicio'} value={solicitud.fechaInicio} />
                </Box>
              </Grid>
              <Grid item xs={3} sx={{p:'1rem 1rem 1rem 1rem'}}>
                <Box sx={{p:'0.9rem 1rem 1rem 1rem'}} >
                  <MyReadOnlyTextField id={'fechaFinal'} label={'Fecha Final'} value={solicitud.fechaFinal} />
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12} alignItems="center">
              <MyReadOnlyTextField id={'ruta'}
                           label={'Ruta'}
                           value={solicitud.ruta} />
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{fontSize:'0.9rem', fontFamily: RobotoCondensedRegular }}>
                Responsables de la Recolección / transporte *
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <MyTableRecolectores id={'responsablesRecoleccion'}
                                   formValues={solicitud}
                                   icon={<LocalShippingIcon sx={{fontSize: '14px', color:'silver'}} />}
                                   canEdit={false} />
            </Grid>
          </> : null
      }
      <Grid item xs={5} />
      <Grid item xs={7} >
        <MyButtonBacan3 label={'AUTORIZACIÓN (PDF)'}
                        icon={FileDownloadIcon}
                        onClick={() => API.fetchDownloadPDF({
                          solicitudId,
                          subfolder: 'reporte-drm-Externa',
                          filename: (solicitud.pdfLded !== '') ? solicitud.pdfLded : solicitud.pdf
                        })}
                        width={'12rem'} />
      </Grid>
    </Grid>
  )
}
