import React, { useState} from 'react'
import {
  Card,
  CardActions,
  Grid,
  Stack,
  Button
} from '@mui/material'
import {
  fetchInstanciaProcesoRO,
  handleCrearProceso, handleCrearProceso0405,
  handleRefreshTareas, setErrorMsg, setInstanciasTarea, setLastSolicitudIdCreated,
} from 'src/features/App/sliceApp'
import {useDispatch, useSelector} from 'react-redux'
import {color02} from 'src/styles/styles'
import {useNavigate} from 'react-router-dom'
import {MyConfirm} from 'src/components/MyConfirm'
import {MyCardHeader} from "../../../components/MyCardHeader"
import {MyCardContent} from "../../../components/MyCardContent"
import {Tarea} from "./Tarea"
import {WF0710Aprobada} from "./WF0710Aprobada"
import {f} from "../../../commons";
import API from "../API";

const WF0102 = ({aprobada, payload, dispatch, navigate, dialogData, setDialogData, perfilUsuario}) => {

  const menu = [
    {
      key:'ATM',
      description:'Acuerdo de Transferencia de Material',
      action:()=> {
        setDialogData({
          ...dialogData,
          message: 'Va a crear una nueva solicitud de Acuerdo de Transferencia de Material',
          messsageAfterOk: 'Se ha creado una nueva solicitud de Acuerdo de Transferencia de Material',
          openDialog: true,
          okAction: () => {
            ////////////////////////////////////////////////////////////////////////////////////////////////////////////
            const payload = JSON.parse(aprobada.payloadSolicitud)
            // prepare centros de documentación (in-situ)
            let recursosInSitu = [...payload.InformeTecnicoMaate?.recursosInSitu]
            let muestras = payload?.InformeTecnicoMaate?.muestras
            recursosInSitu.forEach(recursoInSitu => {
              muestras?.filter(it => it.taxonid === recursoInSitu.taxonid)?.forEach(muestra => {
                muestra.cantidadAutorizada = muestra.saldorATM
                muestra.cantidadSolicitada = 0
              })
            })
            payload.InformeTecnicoMaate.recursosInSitu = recursosInSitu
            // prepare centros de documentación (ex-situ)
            let centrosDocumentacion = [...payload?.InformeTecnicoMaate?.centrosDocumentacion]
            centrosDocumentacion.forEach(centroDocumentacion => {
              centroDocumentacion.recursos?.map(recurso => {
                recurso.cantidadAutorizada = recurso.saldoATM // ?? recurso.cantidadAutorizada
                recurso.cantidadSolicitada = 0
              })
            })
            // console.log('CD : ', centrosDocumentacion)
            payload.InformeTecnicoMaate.centrosDocumentacion = centrosDocumentacion
            const aprob = {...payload}
            dispatch(handleCrearProceso(
              55000005,
              perfilUsuario?.id,
              perfilUsuario?.codigoPerfilUsuario,
              {...aprobada, payloadSolicitud:JSON.stringify(aprob)}
            ))
            setTimeout(function () { dispatch(handleRefreshTareas(perfilUsuario.id)) }, 1500)
          }
        })
      }
    },
    {
      key:'DMR',
      description:'Declaración de Movilización de Recursos',
      action:()=> {
        const payload = JSON.parse(aprobada.payloadSolicitud)
        // if(['in-situ', 'in-ex-situ'].includes(payload.Propuesta.recoleccionDeRecursos)) {
          setDialogData({
            ...dialogData,
            message: 'Va a crear una nueva Declaración de Movilización de Recursos',
            messsageAfterOk: 'Se ha creado una nueva Declaración de Movilización de Recursos',
            openDialog: true,
            okAction: () => {
              ////////////////////////////////////////////////////////////////////////////////////////////////////////////
              const payload = JSON.parse(aprobada.payloadSolicitud)
              // prepare centros de documentación (in-situ)
              let recursosInSitu = [...payload.InformeTecnicoMaate?.recursosInSitu]
              let muestras = payload?.InformeTecnicoMaate?.muestras
              recursosInSitu.forEach(recursoInSitu => {
                muestras?.filter(it => it.taxonid === recursoInSitu.taxonid)?.forEach(muestra => {
                  muestra.cantidadAutorizada = muestra.saldoDRM
                  muestra.cantidadSolicitada = 0
                })
              })
              payload.InformeTecnicoMaate.recursosInSitu = recursosInSitu
              // prepare centros de documentación (ex-situ)
              let centrosDocumentacion = [...payload?.InformeTecnicoMaate?.centrosDocumentacion]
              console.log(payload?.InformeTecnicoMaate?.centrosDocumentacion)
              centrosDocumentacion.forEach(centroDocumentacion => {
                centroDocumentacion.recursos?.map(recurso => {
                  recurso.cantidadAutorizada = recurso.saldoDRM //?? recurso.cantidadAutorizada
                  recurso.cantidadSolicitada = 0
                })
              })

              payload.InformeTecnicoMaate.centrosDocumentacion = centrosDocumentacion
              ////////////////////////////////////////////////////////////////////////////////////////////////////////////
              const aprob = {
                Solicitante:          payload.Solicitante,
                Propuesta:            payload.Propuesta,
                Personal:             payload.Personal,
                Resolucion:           payload.Resolucion,
                AccesoConocimiento:   payload.AccesoConocimiento,
                RecursosExSitu:       payload.RecursosExSitu,
                RecursosInSitu:       payload.RecursosInSitu,
                Declaracion:          payload.Declaracion,
                InformeTecnicoMaate:  payload.InformeTecnicoMaate,
              }
              dispatch(handleCrearProceso(
                55000004,
                perfilUsuario?.id,
                perfilUsuario?.codigoPerfilUsuario,
                {...aprobada, payloadSolicitud:JSON.stringify(aprob)}
              ))
              setTimeout(function () {
                dispatch(handleRefreshTareas(perfilUsuario.id))
              }, 1500)
            }
          })
        // }
        // else {
        //   alert('No se puede iniciar este procesos para recolección de recursos ex-situ')
        // }
      }
    },
    {
      key: 'MPI',
      description: 'Modificación de la solicitud',
      action: () => {
        setDialogData({
          ...dialogData,
          message: '¿Desea crear una Solicitud de Modificación al Permiso de Investigación?',
          messsageAfterOk: 'Se ha creado una Solicitud de Modificación al Permiso de Investigación',
          openDialog: true,
          okAction: () => {
            setDialogData({
              ...dialogData,
              message: 'Va a duplicar un proceso',
              messsageAfterOk: 'Se ha creado una nueva instancia de flujo de proceso para la solicitud con identificador ',
              openDialog: true,
              okAction: () => {
                // console.log(aprobada.idProceso, perfilUsuario.id, aprobada)
                const json = JSON.parse(aprobada.payloadSolicitud)
                let personal = [...json['Personal'].personal]
                if(f.isValid(personal)) {
                  personal.forEach(it => it.pasaporte = '')
                }
                if(f.isValid(json['RecursosExSitu'].centrosDocumentacion) && json['RecursosExSitu'].centrosDocumentacion.length > 0) {
                  let centrosDocumentacion = [...json['RecursosExSitu'].centrosDocumentacion]
                  centrosDocumentacion.forEach(it => {it.adjunto = ''})
                  json['RecursosExSitu'].centrosDocumentacion = centrosDocumentacion
                }
                const Personal = {...json['Personal'], personal}
                let newPayload = {
                  solicitudOriginal:   {...aprobada, payloadSolicitud: json},
                  Recursos:            json['Recursos'],
                  Solicitante:         json['Solicitante'],
                  Propuesta:           {...json['Propuesta'], cartaPatrocidador: '', cartaApoyo: '', convenioApoyo: '', repLegalApoyo: ''},
                  RecursosInSitu:      json['RecursosInSitu'] ?? {},
                  RecursosExSitu:      json['RecursosExSitu'] ?? {},
                  AccesoConocimiento:  json['AccesoConocimiento'] ?? {},
                  Personal:            Personal,
                  Declaracion:         json['Declaracion'],
                }
                const newIS = newPayload.RecursosInSitu.muestras
                const maateIS = newPayload.solicitudOriginal.payloadSolicitud.InformeTecnicoMaate.muestras
                newIS.forEach(it => {
                  const maate = maateIS.filter(it2 => it2.id === it.id)[0]
                  it.cantidadSolicitada = maate.cantidadAutorizada
                  it.cantidadAutorizada = maate.cantidadAutorizada
                })
                const newES = newPayload?.RecursosExSitu?.centrosDocumentacion
                const maateES = newPayload?.solicitudOriginal?.payloadSolicitud?.InformeTecnicoMaate?.centrosDocumentacion
                if(f.isValid(newES) && newES.length > 0) {
                  newES.forEach(it => {
                    const maates = maateES.filter(it2 => it2.id === it.id)[0]
                    it.recursos.forEach(it3 => {
                      it3.cantidadSolicitada = maates.recursos.filter(it4 => it3.id === it4.id)[0].cantidadAutorizada
                      it3.cantidadAutorizada = maates.recursos.filter(it4 => it3.id === it4.id)[0].cantidadAutorizada
                    })
                  })
                }
                dispatch(handleCrearProceso0405(aprobada.idProceso, perfilUsuario.id, newPayload))
                setTimeout(function(){
                  dispatch(handleRefreshTareas(perfilUsuario.id))
                },1500)
              }
            })
          }
        })
      }
    },

    {
      key: 'RSEG',
      description: 'Reporte de seguimiento',
      action: () => {
        setDialogData({
          ...dialogData,
          message: '¿Desea crear una solicitud de reporte de seguimiento?',
          messsageAfterOk: 'Se ha creado una Solicitud de reporte de seguimiento',
          openDialog: true,
          okAction: () => {
            setDialogData({
              ...dialogData,
              message: 'Va a solicitud de reporte de seguimiento',
              messsageAfterOk: 'Se ha creado una nueva instancia de flujo de proceso para la solicitud con identificador ',
              openDialog: true,
              okAction: () => {
                let json = {...aprobada, payloadSolicitud: JSON.parse(aprobada.payloadSolicitud)}
                json.payloadSolicitud.solicitudOriginal = {}
                const newPayload = {solicitudOriginal: json}
                dispatch(handleCrearProceso15(55000023, perfilUsuario?.id, perfilUsuario?.codigoPerfilUsuario, newPayload))
                setTimeout(function(){
                  dispatch(handleRefreshTareas(perfilUsuario.id))
                },1500)
              }
            })
          }
        })
      }
    },
    {
      key: 'CERRAR',
      description: 'Cerrar autorización',
      action: () => {
        setDialogData({
          ...dialogData,
          message: '¿Desea crear una Solicitud para cerrar una autorización?',
          messsageAfterOk: 'Se ha creado una Solicitud para cerrar una autorización',
          openDialog: true,
          okAction: () => {
            const payload = JSON.parse(aprobada.payloadSolicitud)
            const aprob = {
              Solicitante:        payload.Solicitante,
              Propuesta:          payload.Propuesta,
              Personal:           payload.Personal,
              RecursosInSitu:     payload.RecursosInSitu,
              RecursosExSitu:     payload.RecursosExSitu,
              AccesoConocimiento: payload.AccesoConocimiento,
              Resolucion:         payload.Resolucion,
              Declaracion:        payload.Declaracion,
            }
            dispatch(handleCrearProceso(
              55000024,
              perfilUsuario?.id,
              perfilUsuario?.codigoPerfilUsuario,
              {...aprobada, payloadSolicitud:JSON.stringify(aprob)},
              null,
              null
            ))
            setTimeout(function () {
              dispatch(handleRefreshTareas(perfilUsuario.id))
            }, 1500)
          }
        })
      }
    }
  ]

  return (
    <Card variant="outlined" sx={{borderWidth: '1px', borderColor: color02}}>
      <MyCardHeader bgColor={color02}
                    color={'white'}
                    cardTitle={(aprobada.tipoInstanciaProceso === 'Permiso') ?
                      'Solicitud de Permiso de investigación' :
                      'Solicitud de Contrato de acceso con potencial uso comercial'
                    }
                    menu={!aprobada.suspendidaSolicitud ? menu : null}
                    state={{
                      state: (!aprobada.suspendidaSolicitud ? 'aprobada' : 'suspendida'),
                      tip: !aprobada.suspendidaSolicitud ? 'Aprobada' : 'Suspendida'
                    }}/>
      <MyCardContent h1={aprobada.solicitudNumeroSolicitud}
                     h2={payload.Resolucion?.fecha}
                     content={aprobada.nombreProyectoSolicitud}/>
      <CardActions sx={{backgroundColor: 'white', borderTop: `1px solid ${color02}`}}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
              <Button size='small'
                      onClick={() => {
                        dispatch(fetchInstanciaProcesoRO(aprobada.id))
                        setTimeout(() => navigate('/solicitudro'), 500)
                      }}
                      sx={{width: '40%', color: 'white', backgroundColor: color02}}
                      variant='outlined'>VER</Button>
            </Stack>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  )
}

export const Aprobada =({aprobada, tareas, WF0710Aprobadas}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const perfilUsuario = useSelector(state => state.app.perfilUsuario)
  // console.log('::0011:: ', aprobada)
  const [dialogData, setDialogData] = useState({
    title: 'ATENCIÓN',
    message: '[message]',
    openDialog: false,
    okAction: () => {
      alert('NO IMPLEMENTADO . . .')
    },
    cancelAction: () => alert('[cancelAction]'),
    messsageAfterOk: 'Se ejecutó',
    mode: 'OK_CANCEL_AFTEROK',
  })

  const payload = JSON.parse(aprobada.payloadSolicitud)
  return (
    <>
      <Grid item xs={3} sx={{p:'0 0.5rem 0.5rem 0.5rem'}}>
        <WF0102 aprobada={aprobada}
                payload={payload}
                dispatch={dispatch}
                navigate={navigate}
                dialogData={dialogData}
                setDialogData={setDialogData}
                perfilUsuario={perfilUsuario} />
      </Grid>
      {
        tareas?.filter(it => !['Aprobada', 'Denegada'].includes(it.instanciaProcesoSolicitudEstadoSolicitud) && it.instanciaProcesoNumeroSolicitudPadre === aprobada.solicitudNumeroSolicitud)?.map((it, idx) => <Tarea key={idx} tarea={it} suspendida={aprobada.suspendidaSolicitud}/>)
      }
      {
        WF0710Aprobadas.filter(it => it.numeroSolicitudPadre === aprobada.solicitudNumeroSolicitud).map((it, i) => {
          return (
            <WF0710Aprobada key={i} aprobada={it} payload={payload} tareas={tareas} suspendida={aprobada.suspendidaSolicitud}/>
          )
        })
      }
      <MyConfirm dialogData={dialogData} setDialogData={setDialogData} />
    </>
  )
}

export const handleCrearProceso15 = (
  idProceso,
  idPerfilUsuario,
  codigoPerfilUsuario,
  solicitudAprobada={},
  tipoInstanciaProceso=null,
  solicitudId=null
) => {
  // console.log('::-> ', idProceso, idPerfilUsuario, codigoPerfilUsuario, solicitudAprobada)
  const json = solicitudAprobada.solicitudOriginal
  /*
  estadoInstanciaProceso: "Completado"
  estadoSolicitud: "Aprobada"
  id: 31812
  idPerfilUsuario: 4376
  idProceso: 55000014
  idSolicitud: 31811
  nombreProceso: "WF0102 - Permiso Contrato Investigacion Cientifica [Usuario]"
  nombreProyectoSolicitud: "CONTRATO ::::\nLINEA NO ACEPTA CONTRATO"
  numeroPermisoSolicitud : null
  numeroSolicitudPadre : null
  payloadSolicitud: {Personal: {…}, Propuesta: {…}, Resolucion: {…}, Declaracion: {…}, Solicitante: {…}, …}
  perfilUsuarioIdSolicitud: 4376
  solicitudNumeroSolicitud : "SCPC-2022-31811"
  solicitudPadreId: null
  suspendidaSolicitud: false
  tipoInstanciaProceso: "Contrato"
  vigenciaSolicitud
  */
  // console.log('. . . . . . . . idProceso: ', idProceso)
  // console.log('. . . . . idPerfilUsuario: ', idPerfilUsuario)
  // console.log('. . . codigoPerfilUsuario: ', codigoPerfilUsuario)
  // console.log('. . . . . NumeroSolicitud: ', json.solicitudNumeroSolicitud)
  // console.log('. . . . . .RecursosInSitu: ', json.payloadSolicitud.RecursosInSitu)
  // console.log('. . . . . .RecursosExSitu: ', json.payloadSolicitud.RecursosExSitu)
  // console.log('. . . .AccesoConocimiento: ', json.payloadSolicitud.AccesoConocimiento)
  // console.log('. . . . . . . Solicitante: ', json.payloadSolicitud.Solicitante)
  // console.log('. . . . . . . . Propuesta: ', json.payloadSolicitud.Propuesta)
  // console.log('. . . . . . . . .Personal: ', json.payloadSolicitud.Personal)
  // console.log('. . . . . . . .Resolución: ', json.payloadSolicitud.Resolucion)
  // console.log('. . . . . . . . . .Father: ', json.payloadSolicitud.father)
  // console.log('. . . InformeTecnicoMaate: ', json.payloadSolicitud.InformeTecnicoMaate)
  // return (dispatch) => {
  //   dispatch(setErrorMsg('ERROR, usuario y/o contraseña incorrectos'))
  // }

  if(!f.isValid(idPerfilUsuario)) {
    return (dispatch) => {
      dispatch(setErrorMsg('ERROR, usuario y/o contraseña incorrectos'))
    }
  } else {
    return (dispatch) => {
      // console.log('01 > ', idProceso, idPerfilUsuario, codigoPerfilUsuario, null, tipoInstanciaProceso, json.solicitudNumeroSolicitud, null)
      // console.log('02 > ', solicitudAprobada.solicitudOriginal.solicitudNumeroSolicitud,
      //   f.isValid(solicitudAprobada.solicitudOriginal.solicitudNumeroSolicitud)?
      //   {
      //     Solicitud:{
      //       aprobadaIdentificador: solicitudAprobada.solicitudOriginal.solicitudNumeroSolicitud,
      //       solicitudAprobada: json
      //     }
      //   }:{})

      // console.log('>:>:>:>:>:> ', idProceso, 1153, codigoPerfilUsuario, null, json.solicitudNumeroSolicitud, null)
      API.crearInstanciaProceso(idProceso, 1153, codigoPerfilUsuario, null, json.solicitudNumeroSolicitud, null)
        .then( (proceso) => {
          dispatch(setErrorMsg(''))
          return proceso
        })
        .then((proceso) => {
          dispatch(setLastSolicitudIdCreated(proceso?.solicitud?.numeroSolicitud))
          API.fetchInstanciaProceso(proceso.id).then(pr => {
            if(!f.isValid(solicitudId)) {
              // console.log('C proceso 15:', pr, pr.instanciaTareaList[0].id, idPerfilUsuario)
              Promise.all([
                API.asignarTareaPerfilUsuario(pr.instanciaTareaList[0].id, idPerfilUsuario),
                API.setPayload(pr.id,JSON.stringify(
                  f.isValid(solicitudAprobada.solicitudOriginal.solicitudNumeroSolicitud)?
                    {
                      Solicitud:{
                        aprobadaIdentificador: solicitudAprobada.solicitudOriginal.solicitudNumeroSolicitud,
                        solicitudAprobada: json
                      }
                    }:{}
                ))
              ]).then(([r1, r2]) => {
                API.fetchTareasPerfilUsuario(idPerfilUsuario).then((tareas) => {
                  dispatch(setInstanciasTarea(tareas))
                }).catch((error) => {
                  dispatch(setInstanciasTarea([]))
                })
              })
            } else {
              Promise.all([
                API.asignarTareaPerfilUsuario(pr.instanciaTareaList[0].id, idPerfilUsuario),
              ]).then(([r1]) => {
                API.fetchTareasPerfilUsuario(idPerfilUsuario).then((tareas) => {
                  dispatch(setInstanciasTarea(tareas))
                }).catch((error) => {
                  dispatch(setInstanciasTarea([]))
                })
              })
            }
          })
        })
        .catch((error) => {
            dispatch(setErrorMsg('ERROR, no se pudo crear proceso'))
          }
        )
    }
  }
}
