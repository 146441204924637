import React, {useEffect, useState} from 'react'
import { useSelector} from 'react-redux'
import {
  Box,
  Grid,
} from '@mui/material'
import {format, parseISO} from 'date-fns'
import {es} from 'date-fns/locale'
import {dialog} from 'src/styles/styles'
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined'
import {MySwitch} from 'src/components/MySwitch'
import {rulesFor} from 'src/features/P01Solicitud'
import API from 'src/features/App/API'
import {MySubtitle} from 'src/components/MySubtitle'
import {f} from 'src/commons/f'
import {MyTextField} from 'src/components/MyTextField'
import {Stack} from "@mui/system";
import Table from "@mui/material/Table"
import TableRow from "@mui/material/TableRow"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import TableBody from "@mui/material/TableBody";
import {MyButtonBacan2} from 'src/components/MyButtonBacan2'
import SearchIcon from '@mui/icons-material/Search';

const AccesoConocimiento = ({payload,mainFormValues,incrementCounter}) => {
  const section = 'AccesoConocimiento'
  if (!!!payload[section]) {
    payload[section] = {}
  }
  const bandeja = useSelector(state => state.app.bandeja)
  const canEdit = bandeja === 'entrada'
  const RULES = rulesFor(section)
  const emptyPayload = RULES.emptyPayload()
  const handlesChange = RULES.handlesChange()
  const validators = RULES.validators()
  const initErrors = RULES.initErros()
  const [formValues, setFormValues] = useState({...emptyPayload, ...payload[section]})
  const [formErrors, setFormErrors] = useState({...initErrors})
  const handleChange = (e) => API.handleChange(e, bandeja, setFormValues, formValues)
  const {clpis, clpiContratos} = useSelector(state => state.app)
  useEffect(() => {
    mainFormValues[section] = formValues
    incrementCounter()
  }, [formValues])

  const hstyle = {width:'20%', fontWeight:'bold', p:'0.5rem'}
  const dstyle = {width:'20%', p:'0.5rem'}
  return(
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={12} sx={dialog.titleContainer}>
          <MySubtitle subtitle={'Conocimiento tradicional'} />
        </Grid>
        <Grid item xs={12}>
          <MySwitch id='accesoConocimiento'
                    label={'¿Existe acceso, uso o aprovechamiento de conocimientos tradicionales asociados a la biodiversidad?'}
                    formValues={formValues}
                    canEdit={canEdit}
                    handleChange={(e,v) => handlesChange['accesoConocimiento'](e,v,canEdit,setFormValues,formValues)}/>
        </Grid>
        {formValues['accesoConocimiento'] &&
          <>
            <Grid item xs={4}>
              <Stack direction={'row'}>
                <Box sx={{width:'70%'}}>
                  <MyTextField id={'clpi'}
                               label={'Número de registro CLPI'}
                               canEdit={canEdit}
                               setFormValues={setFormValues}
                               formValues={formValues}
                               formErrors={formErrors}
                               error={formErrors['clpi']}
                               placeholder={'SENADI-CLPI-###-####'}
                               handleChange={(e) => {
                                 handlesChange['clpi'](
                                   e,
                                   canEdit,
                                   setFormValues,
                                   formValues,
                                   validators['clpi'],
                                   setFormErrors,
                                   formErrors,
                                   canEdit
                                 )}
                               }
                               icon={<ArticleOutlinedIcon sx={dialog.textFieldIcon}/>} />
                </Box>
                <Box sx={{width:'30%'}}>
                  <MyButtonBacan2 label={'buscar'}
                                  icon={SearchIcon}
                                  onClick={() => {
                                    API.buscarCLPI(formValues.clpi).then((res) => {
                                      setFormValues({
                                        ...formValues,
                                        numTramite: f.StringOrEmpty(res.numTramite),
                                        fechaPresentacion: f.StringOrEmpty(res.fechaPresentacion),
                                        fechaOtorgamiento: f.StringOrEmpty(res.fechaOtorgamiento),
                                        fechaFinVigencia: f.StringOrEmpty(res.fechaFinVigencia),
                                        fechaRegistro: f.StringOrEmpty(res.fechaRegistro),
                                        titulo: f.StringOrEmpty(res.titulo),
                                        legitimosPoseedores: f.StringOrEmpty(res.legitimosPoseedores),
                                        nacionalidad: f.StringOrEmpty(res.nacionalidad),
                                        solicitante: f.StringOrEmpty(res.nombreSolicitante),
                                        paisOrigen: f.StringOrEmpty(res.paisOrigenSol),
                                        usuariosAutorizados: f.string2json(res.usuariosAutorizados, '[]'),
                                        tipo: f.StringOrEmpty(res.tipo),
                                      })
                                    })
                                  }}
                                  padding={'1.8rem 0 0 0'} />
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={8}>
              <Stack direction={'row'}>
                <Box sx={{width:'80%'}}>
                  <MyTextField id={'contratoAccesoConocimiento'}
                               label={'Número de registro de contrato de acceso, uso o aprovechamiento del conocimiento tradicional'}
                               canEdit={canEdit}
                               setFormValues={setFormValues}
                               formValues={formValues}
                               formErrors={formErrors}
                               placeholder={'SENADI-C-###-####'}
                               error={formErrors['contratoAccesoConocimiento']}
                               handleChange={(e) => {
                                 handlesChange['contratoAccesoConocimiento'](
                                   e,
                                   canEdit,
                                   setFormValues,
                                   formValues,
                                   validators['contratoAccesoConocimiento'],
                                   setFormErrors,
                                   formErrors,
                                   canEdit
                                 )}
                               }
                               icon={<ArticleOutlinedIcon sx={dialog.textFieldIcon}/>} />
                </Box>
                <Box sx={{width:'20%'}}>
                  <MyButtonBacan2 label={'buscar'}
                                  icon={SearchIcon}
                                  onClick={() => {
                                    API.buscarCONTRATO(formValues.contratoAccesoConocimiento).then((res) => {
                                      setFormValues({
                                        ...formValues,
                                        numTramite2: f.StringOrEmpty(res.numTramite),
                                        fechaPresentacion2: f.StringOrEmpty(res.fechaPresentacion),
                                        fechaOtorgamiento2: f.StringOrEmpty(res.fechaOtorgamiento),
                                        fechaFinVigencia2: f.StringOrEmpty(res.fechaFinVigencia),
                                        fechaRegistro2: f.StringOrEmpty(res.fechaRegistro),
                                        titulo2: f.StringOrEmpty(res.titulo),
                                        legitimosPoseedores2: f.StringOrEmpty(res.legitimosPoseedores),
                                        nacionalidad2: f.StringOrEmpty(res.nacionalidad),
                                        solicitante2: f.StringOrEmpty(res.nombreSolicitante),
                                        paisOrigen2: f.StringOrEmpty(res.paisOrigenSol),
                                        usuariosAutorizados2: f.string2json(res.usuariosAutorizados, '[]'),
                                        tipo2: f.StringOrEmpty(res.tipo),
                                      })
                                    })
                                  }}
                                  padding={'1.8rem 0 0 0'} />
                </Box>
              </Stack>
            </Grid>
            <Grid container spacing={4} >
              <Grid item xs={6}>
                <Box sx={{width:'100%', border: '1px dotted black', borderRadius:'0.5rem'}}>
                  <Table sx={{[`& .${tableCellClasses.root}`]: {borderBottom: "none"}}}>
                    <TableBody>
                      <TableRow><TableCell sx={hstyle} align={'left'}>Trámite Nro.</TableCell><TableCell>{formValues.numTramite}</TableCell></TableRow>
                      <TableRow><TableCell sx={hstyle} align={'left'}>Fecha de Presentación</TableCell><TableCell>{f.isValidNotEmpty(formValues.fechaPresentacion) && format(parseISO(formValues.fechaPresentacion), 'dd MMMM yyyy', {locale: es})}</TableCell></TableRow>
                      <TableRow><TableCell sx={hstyle} align={'left'}>Fecha de otorgamiento del CLPI</TableCell><TableCell>{f.isValidNotEmpty(formValues.fechaOtorgamiento) && format(parseISO(formValues.fechaOtorgamiento), 'dd MMMM yyyy', {locale: es})}</TableCell></TableRow>
                      <TableRow><TableCell sx={hstyle} align={'left'}>Vigencia</TableCell><TableCell>{f.isValidNotEmpty(formValues.fechaFinVigencia) && format(parseISO(formValues.fechaFinVigencia), 'dd MMMM yyyy', {locale: es})}</TableCell></TableRow>
                      <TableRow><TableCell sx={hstyle} align={'left'}>Fecha de Registro</TableCell><TableCell>{f.isValidNotEmpty(formValues.fechaRegistro) && format(parseISO(formValues.fechaRegistro), 'dd MMMM yyyy', {locale: es})}</TableCell></TableRow>
                      <TableRow><TableCell sx={hstyle} align={'left'}>Título del Plan de acceso, uso o aprovechamiento del conocimiento tradicional</TableCell><TableCell>{formValues.titulo}</TableCell></TableRow>
                      <TableRow><TableCell sx={hstyle} align={'left'}>Legítimos Poseedores</TableCell><TableCell>{formValues.legitimosPoseedores}</TableCell></TableRow>
                      <TableRow><TableCell sx={hstyle} align={'left'}>Nacionalidad / Pueblo / Comunidad</TableCell><TableCell>{formValues.nacionalidad}</TableCell></TableRow>
                      <TableRow><TableCell sx={hstyle} align={'left'}>Solicitante</TableCell><TableCell>{formValues.solicitante}</TableCell></TableRow>
                      <TableRow><TableCell sx={hstyle} align={'left'}>País Origen Solicitante</TableCell><TableCell>{formValues.paisOrigen}</TableCell></TableRow>
                      <TableRow>
                        <TableCell sx={hstyle} align={'left'}>Usuarios de CC.TT. Autorizados</TableCell>
                          <TableCell>
                            {/*{formValues.usuariosAutorizados?.map(it => `${it.usuario} (${it.pais})`)?.join(', ')}*/}
                          </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box sx={{width:'100%', border: '1px dotted black', borderRadius:'0.5rem'}}>
                  <Table sx={{[`& .${tableCellClasses.root}`]: {borderBottom: "none"}}}>
                    <TableBody>
                      <TableRow><TableCell sx={hstyle} align={'left'}>Trámite Nro.</TableCell><TableCell>{formValues.numTramite2}</TableCell></TableRow>
                      <TableRow><TableCell sx={hstyle} align={'left'}>Fecha de Presentación</TableCell><TableCell>{f.isValidNotEmpty(formValues.fechaPresentacion2) && format(parseISO(formValues.fechaPresentacion2), 'dd MMMM yyyy', {locale: es})}</TableCell></TableRow>
                      <TableRow><TableCell sx={hstyle} align={'left'}>Fecha de suscripción del contrato</TableCell><TableCell>{f.isValidNotEmpty(formValues.fechaOtorgamiento2) && format(parseISO(formValues.fechaOtorgamiento2), 'dd MMMM yyyy', {locale: es})}</TableCell></TableRow>
                      <TableRow><TableCell sx={hstyle} align={'left'}>Vigencia</TableCell><TableCell>{f.isValidNotEmpty(formValues.fechaFinVigencia2) && format(parseISO(formValues.fechaFinVigencia2), 'dd MMMM yyyy', {locale: es})}</TableCell></TableRow>
                      <TableRow><TableCell sx={hstyle} align={'left'}>Fecha de Registro</TableCell><TableCell>{f.isValidNotEmpty(formValues.fechaRegistro2) && format(parseISO(formValues.fechaRegistro2), 'dd MMMM yyyy', {locale: es})}</TableCell></TableRow>
                      <TableRow><TableCell sx={hstyle} align={'left'}>Título del Plan de acceso, uso o aprovechamiento del conocimiento tradicional</TableCell><TableCell>{formValues.titulo2}</TableCell></TableRow>
                      <TableRow><TableCell sx={hstyle} align={'left'}>Legítimos poseedores</TableCell><TableCell>{formValues.legitimosPoseedores2}</TableCell></TableRow>
                      <TableRow><TableCell sx={hstyle} align={'left'}>Nacionalidad / Pueblo / Comunidad</TableCell><TableCell>{formValues.nacionalidad2}</TableCell></TableRow>
                      <TableRow><TableCell sx={hstyle} align={'left'}>Solicitante</TableCell><TableCell>{formValues.solicitante2}</TableCell></TableRow>
                      <TableRow><TableCell sx={hstyle} align={'left'}>País Origen Solicitante</TableCell><TableCell>{formValues.paisOrigen2}</TableCell></TableRow>
                      <TableRow>
                        <TableCell sx={hstyle} align={'left'}>Usuarios de CC.TT. Autorizados</TableCell>
                        <TableCell>
                          {/*{f.isValidNotEmpty(formValues.usuariosAutorizados2) && formValues.usuariosAutorizados2?.map(it => `${it.usuario} (${it.pais})`)?.join(', ')}*/}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Box>
              </Grid>
            </Grid>
          </>
        }
        {!formValues['accesoConocimiento'] ?
          <>
            <Grid item xs={12}>
              <MySwitch id='ambitoComunitario'
                        label={'¿La investigación se desarrolla en un ámbito comunitario ancestral?'}
                        formValues={formValues}
                        canEdit={canEdit}
                        handleChange={(e, v) => handlesChange['ambitoComunitario'](e, v, canEdit, setFormValues, formValues)}/>
            </Grid>
            <Grid item xs={12}>
              <MySwitch id='valoresReligiososCultutrales'
                        label={'¿La investigación hace referencia a temas de cosmovisión valores religiosos o culturales?'}
                        formValues={formValues}
                        canEdit={canEdit}
                        handleChange={(e, v) => handlesChange['valoresReligiososCultutrales'](e, v, canEdit, setFormValues, formValues)}/>
            </Grid>
            <Grid item xs={12}>
              <MySwitch id='recursosBiologico'
                        label={'¿La investigación se relaciona con un ritual curativo donde se utilice los recursos biológicos?'}
                        formValues={formValues}
                        canEdit={canEdit}
                        handleChange={(e, v) => handlesChange['recursosBiologico'](e, v, canEdit, setFormValues, formValues)}/>
            </Grid>
            <Grid item xs={12}>
              <MySwitch id='practicasAncestrales'
                        label={'¿La investigación versan sobre mecanismos y prácticas de siembra cosecha, mantenimiento y recolección de semillas entre otras prácticas agropecuarias ancestrales?'}
                        formValues={formValues}
                        canEdit={canEdit}
                        handleChange={(e, v) => handlesChange['practicasAncestrales'](e, v, canEdit, setFormValues, formValues)}/>
            </Grid>
            <Grid item xs={12}>
              <MySwitch id='etnozoologicos'
                        label={'¿La investigación se basa en estudio de carácter etnobotánico o etnozoologico?'}
                        formValues={formValues}
                        canEdit={canEdit}
                        handleChange={(e, v) => handlesChange['etnozoologicos'](e, v, canEdit, setFormValues, formValues)}/>
            </Grid>
            <Grid item xs={12}>
              <MySwitch id='aConocimientoTradicional'
                        label={'¿La investigación hace referencia sobre el uso de un recurso biológico planta o animal asociado a un conocimiento tradicional?'}
                        formValues={formValues}
                        canEdit={canEdit}
                        handleChange={(e, v) => handlesChange['aConocimientoTradicional'](e, v, canEdit, setFormValues, formValues)}/>
            </Grid>
            <Grid item xs={12}>
              <MySwitch id='derivadosOSimilares'
                        label={'¿La investigación hace referencia a compuestos biológicos naturales para la elaboración de productos alimenticios dieteticos colorantes cosméticos y derevados o similares?'}
                        formValues={formValues}
                        canEdit={canEdit}
                        handleChange={(e, v) => handlesChange['derivadosOSimilares'](e, v, canEdit, setFormValues, formValues)}/>
            </Grid>
            <Grid item xs={12}>
              <MySwitch id='medicinaTradicional'
                        label={'¿La investigacion se basa en combinaciones de extractos biológicos naturales para la preparación de medicina tradicional?'}
                        formValues={formValues}
                        canEdit={canEdit}
                        handleChange={(e, v) => handlesChange['medicinaTradicional'](e, v, canEdit, setFormValues, formValues)}/>
            </Grid>
          </>:null
        }
      </Grid>
    </Box>
  )
}

export default AccesoConocimiento
