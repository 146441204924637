import React, {useEffect, useRef, useState} from 'react'
import {AppBar, Box, CssBaseline, Grid, Stack, Tab, Typography} from '@mui/material'
import {add, format, parse} from 'date-fns'
import {es} from 'date-fns/locale'
import {accordeonBox, dialog} from 'src/styles/styles'
import {useDispatch, useSelector} from 'react-redux'
import API from "src/features/App/API";
import {MyAreaTextField} from "src/components/MyAreaTextField";
import {handCompletarTareaAndSalir, handleClear, handleSaveSolicitud} from "src/features/App/sliceApp";
import {MySendButton} from 'src/components/MySendButton'
import {MySubtitle} from 'src/components/MySubtitle'
import {Solicitante} from 'src/features/P01Solicitud/subcomponents/Solicitante'
import GLOBALS from 'src/features/App/globals'
import {SectionTitle} from "../../components/SectionTitle";
import {MyReadOnlyTextField} from "../../components/MyReadOnlyTextField";
import {AccountCircle} from "@mui/icons-material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {MyButtonBacan} from "../../components/MyButtonBacan";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import Toolbar from "@mui/material/Toolbar";
import {MyGobackButton, MySaveButton} from "../../components/MyCommonButtons";
import {MySwitch} from "../../components/MySwitch";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {P0102PayloadRO} from "./P0102PayloadRO";
import {f} from "../../commons";
import {P0102Expediente} from "./P0102Expediente";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const section = 'RevisarContrato'
  const dispatch = useDispatch()
  const instanciaTarea = useSelector(state => state.app.instanciaTarea)
  const perfilUsuario = useSelector(state => state.app.perfilUsuario)
  const bandeja = useSelector(state => state.app.bandeja)
  const instanciaProceso = useSelector(state => state.app.instanciaProceso)
  const solicitud = {...instanciaProceso.solicitud}
  const payload = !!solicitud.payload?JSON.parse(solicitud.payload):{}
  const slct = {...solicitud, payload : JSON.parse(solicitud.payload)}
  const today = format(new Date(), 'dd-MMMM-yyyy', {locale: es})
  const emptyPayload = {
    fecha:           today,
    identificador:   solicitud.numeroSolicitud,
    estaDeacuerdo:   true,
    requiereCambios: false,
    tiempoVigencia:  '' + payload.Propuesta.plazo + ' meses',
    observaciones:   '',
  }
  const [formValues, setFormValues] = useState({...emptyPayload, ...payload[section]})
  const handleChange = (e) => API.handleChange(e, bandeja, setFormValues, formValues)
  const dResolucion = parse(slct.payload.Resolucion.fecha,'dd-MMMM-yyyy', new Date(), {locale: es})
  const plazo = Number(slct.payload.Propuesta.plazo)
  const dPlazo = add(dResolucion, {months: plazo})
  const [counter, setCounter] = useState(-1)  // updating counter
  useEffect(() => {setCounter(counter + 1)}, [formValues])
  const nombreOrganizacion = 'Senescyt'
  const resolucionSubfolder = `resolucion-${nombreOrganizacion}`
  const resolucionFilename = `resolucion-${formValues.identificador}-${nombreOrganizacion}`
  const resolucionFilenamePDFsigned = `${resolucionFilename}-firmado.pdf`
  const contratoSubfolder = `contrato-${nombreOrganizacion}`
  const contratoFilename = `contrato-${formValues.identificador}-${nombreOrganizacion}`
  // const contratoFilenamePDF = `${contratoFilename}.pdf`
  // const filenamePDFuploaded = `${contratoFilename}-upld.pdf`
  // const contratoFilenamePDFsigned = `${contratoFilename}-firmado.pdf`
  const [myTab, setMytab] = useState('1');
  const handleChangeTab = (event, newTab) => {
    setMytab(newTab);
  }
  const contratoPdf = (payload?.ElaborarContrato?.pdfLded !== '')?payload?.ElaborarContrato?.pdfLded:payload?.ElaborarContrato?.pdf
  useEffect(() => {
    if(formValues.requiereCambios) {
      setFormValues({...formValues, estaDeacuerdo:true})
    }
  }, [formValues.requiereCambios]);

  if(!!instanciaProceso.id && !!instanciaTarea.id) {
    return <>
      <Box sx={{...accordeonBox.container, pt:'2rem', height: '100%'}}>
        <AppBar position='fixed'
                color='primary'
                elevation={0}
                sx={{top:'64px', bottom:'auto', margin: 0, padding:0, backgroundColor:'rgba(148, 193, 32, 0.8)'}} >
          <SectionTitle title={'Revisión de contrato de acceso con potencial uso comercial'} />
        </AppBar>
        <TabContext value={myTab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChangeTab}
                     aria-label="lab API tabs example">
              <Tab label="Formulario" value="1" />
              <Tab label="Solicitud" value="2" />
              {
                f.isValid(payload?.solicitudOriginal?.payloadSolicitud) ?
                  <Tab label={'Solicitud original'} value="4"/> : null
              }
              {
                f.isValid(payload?.solicitudOriginal?.payloadSolicitud) ?
                  <Tab label={'Expediente original'} value="5"/> : null
              }
            </TabList>
          </Box>
          <TabPanel value="1">
            <Grid container spacing={1} sx={accordeonBox.container2}>
              <Solicitante solicitud={{solicitud: {payload}}} displayContact={false} />
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Resolución'} />
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{p:'0 1rem 0 0'}} >
                  <MyReadOnlyTextField id='identificador'
                                       label={'Identificador'}
                                       value={solicitud?.numeroSolicitud}
                                       icon={<AccountCircle sx={{fontSize: '14px', color: 'silver', mr: '0.8rem'}} />} />
                  <MyReadOnlyTextField id='fecha'
                                       label={'Fecha'}
                                       value={slct?.payload?.Resolucion?.fecha}
                                       icon={<CalendarMonthIcon sx={{fontSize: '14px', color: 'silver', mr: '0.8rem'}} />} />
                  <MyButtonBacan label={'VER PDF'}
                                 onClick={() => {
                                   const url = `${GLOBALS.mainUrl}/documentos/descargar?filename=vuv-${solicitud?.id}/${resolucionSubfolder}/${resolucionFilenamePDFsigned}`
                                   fetch(url)
                                     .then((res) => { return res.blob(); })
                                     .then((data) => {
                                       const dataPdf = new Blob([data], { type: 'application/pdf' })
                                       const a = document.createElement("a")
                                       a.href = window.URL.createObjectURL(dataPdf)
                                       a.target="_blank"
                                       a.click()
                                     })
                                 }}
                                 myTip={'Ver resolución'}
                                 icon={FileDownloadOutlinedIcon} />
                </Stack>
              </Grid>
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Contrato'} />
              </Grid>
              <Grid item xs={12} style={{padding:'0 24px 0 0.5rem'}} >
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} >
                  <MyReadOnlyTextField id='identificador'
                                       label={'Identificador'}
                                       value={solicitud?.numeroSolicitud}
                                       icon={<AccountCircle sx={{fontSize: '14px', color: 'silver', mr: '0.8rem'}}/>}/>
                  <MyReadOnlyTextField id='fecha'
                                       label={'Fecha de emisión'}
                                       value={format(dResolucion, 'dd-MMM-yyyy', {locale: es})}
                                       icon={<CalendarMonthIcon sx={{fontSize: '14px', color: 'silver', mr: '0.8rem'}} />} />
                  <MyReadOnlyTextField id='plazo'
                                       label={'Vigencia'}
                                       value={`${plazo} meses`}
                                       icon={<CalendarMonthIcon sx={{fontSize: '14px', color: 'silver', mr: '0.8rem'}} />} />
                  <MyReadOnlyTextField id='fechaCaducidad'
                                       label={'Fecha de caducidad'}
                                       value={format(dPlazo, 'dd-MMM-yyyy', {locale: es})}
                                       icon={<CalendarMonthIcon sx={{fontSize: '14px', color: 'silver', mr: '0.8rem'}} />} />
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{p:'0 1rem 0 0'}} >
                  <MySwitch id={'requiereCambios'}
                            canEdit={true}
                            label={'¿Requiere cambios?'}
                            formValues={formValues}
                            fullWidth={false}
                            handleChange={handleChange} />
                  {!formValues.requiereCambios?
                    <MySwitch id={'estaDeacuerdo'}
                              canEdit={true}
                              label={'¿Está de acuerdo con el contenido del documento de contrato?'}
                              formValues={formValues}
                              fullWidth={false}
                              handleChange={handleChange}/>:null
                  }
                  <MyButtonBacan label={'VER PDF'}
                                 onClick={() => {
                                   const url = `${GLOBALS.mainUrl}/documentos/descargar?filename=vuv-${instanciaProceso?.solicitud?.id}/${contratoSubfolder}/${contratoPdf}`
                                   fetch(url)
                                     .then((res) => { return res.blob(); })
                                     .then((data) => {
                                       const dataPdf = new Blob([data], { type: 'application/pdf' })
                                       const a = document.createElement("a")
                                       a.href = window.URL.createObjectURL(dataPdf)
                                       a.target="_blank"
                                       a.click()
                                     })
                                 }}
                                 myTip={'Ver resolución'}
                                 icon={FileDownloadOutlinedIcon} />
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack direction={'row'} justifyContent={'flex-end'}>
                  {!formValues.estaDeacuerdo && !formValues.requiereCambios?
                    <Typography>
                      ISí Usted NO acepta los términos del contrato, entonces el trámite terminará y no se suscribirá el contrato.
                    </Typography>:null
                  }
                  {formValues.estaDeacuerdo && !formValues.requiereCambios?
                    <Typography>
                      Sí Usted acepta los términos del contrato, entonces el siguiente paso es proceder a la suscripción del contrato.
                    </Typography>:null
                  }
                </Stack>
              </Grid>
              { formValues.requiereCambios?
                <Grid item xs={12}>
                  <MyAreaTextField id={'observaciones'}
                                   label={'Observaciones'}
                                   formValues={formValues}
                                   handleChange={handleChange}
                                   rows={10}/>
                </Grid>:null
              }
            </Grid>
          </TabPanel>
          <TabPanel value="2">
            <P0102PayloadRO payload={payload}/>
          </TabPanel>
          {
            f.isValid(payload?.solicitudOriginal?.payloadSolicitud) ?
              <TabPanel value="4">
                <P0102PayloadRO payload={payload.solicitudOriginal.payloadSolicitud}/>
              </TabPanel> : null
          }
          {
            f.isValid(payload?.solicitudOriginal?.payloadSolicitud) ?
              <TabPanel value="5">
                <P0102Expediente payload={payload.solicitudOriginal.payloadSolicitud}/>
              </TabPanel> : null
          }
        </TabContext>
        <CssBaseline/>
        <AppBar position='fixed'
                color='primary'
                elevation={0}
                sx={accordeonBox.bottomBar} >
          <Toolbar>
            <Grid container sx={{p:0, mt:'-1rem'}}>
            <Grid item xs={12} >
              <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} >
                <MyGobackButton onGoback={() => dispatch(handleClear())} />
                <MySaveButton onSave={() => {
                                payload[section]=formValues
                                dispatch(handleSaveSolicitud(
                                  instanciaProceso?.id,
                                  payload,
                                  () => setCounter(0)
                                ))
                              }}
                              disabled={counter <= 0 } />
                <MySendButton onSend={() => {
                                const metadata = JSON.stringify({
                                  "solicitudId": `${instanciaProceso?.solicitud?.id}`,
                                  "contratoAceptado": formValues.estaDeacuerdo,
                                  "requiereCambios": formValues.requiereCambios,
                                })
                                dispatch(handCompletarTareaAndSalir(instanciaProceso.id, instanciaTarea, perfilUsuario.id, metadata))
                              }}
                              disabled={counter > 0}
                              label={'enviar'} />
              </Stack>
            </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </Box>
    </>
  }
  else {
    return null
  }
}
