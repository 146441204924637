import React, {useState} from "react";
import {Button, Card, CardActions, Grid, Stack} from "@mui/material";
import {MyCardHeader} from "../../../components/MyCardHeader";
import {MyCardContent} from "../../../components/MyCardContent";
import {
  fetchPayloadFromProceso,
  handleCrearProceso,
  handleCrearProceso12,
  handleCrearProceso14,
  handleRefreshTareas
} from "../sliceApp";
import {useDispatch, useSelector} from 'react-redux'
import {color02} from 'src/styles/styles'
import {useNavigate} from 'react-router-dom'
import {MyConfirm} from "../../../components/MyConfirm";
import {blue} from "@mui/material/colors";
import {format, parseISO} from 'date-fns'
import {es} from 'date-fns/locale'
import {
  fetchInstanciaTareaInstanciaProceso,
  handleSetBandeja
} from "../sliceApp";
import API from "../API";
import {f} from "../../../commons";
import p16ElaborarInformeSenadi from "../../P16CerrarAutorizacion/P16ElaborarInformeSenadi";

export const WF0710Aprobada = ({aprobada, payload, tareas, suspendida=false}) => {
  const payloadSolicitud = JSON.parse(aprobada.payloadSolicitud)
  // const esInsitu = payloadSolicitud?.Solicitud?.tipo === 'IN-SITU'
  const esExsitu = payloadSolicitud?.Solicitud?.tipo === 'EX-SITU'
  const hayHolotipos = esExsitu ? (payloadSolicitud?.Solicitud?.recursosES?.filter(it => it.holotipo === true)?.length > 0) : false
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const perfilUsuario = useSelector(state => state.app.perfilUsuario)
  const [dialogData, setDialogData] = useState({
    title: 'ATENCIÓN',
    message: '[message]',
    openDialog: false,
    okAction: () => {
      alert('IMPLEMENTACIóN NO ASIGNADA . . .')
    },
    cancelAction: () => alert('[cancelAction]'),
    messsageAfterOk: 'Se ejecutó',
    mode: 'OK_CANCEL_AFTEROK',
  })
  // console.log(':::: payloadSolicitud : ', payloadSolicitud)
  const menu0710 = () => {
    const myMenu = [
      {
        key: 'k1',
        description: 'Registrar permiso de importacion',   //(WF08)
        action: () => {
          setDialogData({
            ...dialogData,
            message: 'Va a crear una nueva Solicitud de permiso de importación de recursos (WF08)',
            messsageAfterOk: 'Se ha creado una nueva Solicitud de permiso de importación de recursos (WF08)',
            openDialog: true,
            okAction: () => {
              const p = JSON.parse(aprobada.payloadSolicitud)
              const solicitudAprobada = p.Solicitud
              solicitudAprobada.recursosES = [...solicitudAprobada.recursosES]?.filter(it => !it.holotipo)?.map(it => ({
                ...it,
                cantidadAutorizada: it.cantidadSolicitada,
                cantidadSolicitada: 0,
              }))
              const solicitudPermiso = JSON.parse(solicitudAprobada.solicitudAprobada)
              let sp = {
                solicitudNumeroSolicitud: aprobada.solicitudNumeroSolicitud,
                payloadSolicitud: JSON.stringify({
                  Solicitante: solicitudPermiso.Solicitante,
                  Propuesta: solicitudPermiso.Propuesta,
                  Personal: solicitudPermiso.Personal,
                  // father: {aprobada},
                  father: {aprobada, payloadSolicitud:{Solicitud:solicitudAprobada}},
                })
              }
              dispatch(handleCrearProceso(55000007, perfilUsuario?.id, perfilUsuario?.idPerfilUsuario, sp))
              setTimeout(function() {
                dispatch(handleRefreshTareas(perfilUsuario.id))
              }, 1500);
            }
          })
        }
      },
      {
        key: 'k2',
        description: 'Solicitar salida de holotipos',
        action: () => {
          setDialogData({
            ...dialogData,
            message: 'Va a crear una nueva Solicitud de salida de holotipos (WF09)',
            messsageAfterOk: 'Se ha creado una nueva Solicitud de salida de holotipos (WF09)',
            openDialog: true,
            okAction: () => {
              const p = JSON.parse(aprobada.payloadSolicitud)
              let solicitudAprobada = p.Solicitud
              solicitudAprobada.recursos = [...solicitudAprobada.recursosES]?.filter(it => it.holotipo)?.map(it => ({
                ...it,
                cantidadAutorizada: it.cantidadSolicitada,
                cantidadSolicitada: 0,
              }))
              const solicitudPermiso = JSON.parse(solicitudAprobada.solicitudAprobada)
              let sp = {
                solicitudNumeroSolicitud: aprobada.solicitudNumeroSolicitud,
                payloadSolicitud: JSON.stringify({
                  Solicitante: solicitudPermiso.Solicitante,
                  Propuesta: solicitudPermiso.Propuesta,
                  Personal: solicitudPermiso.Personal,
                  Resolucion: solicitudPermiso.Resolucion,
                  father: {aprobada, payloadSolicitud:{Solicitud:solicitudAprobada}},
                })
              }
              dispatch(handleCrearProceso(55000009, perfilUsuario?.id, perfilUsuario?.idPerfilUsuario, sp))
              setTimeout(function(){
                dispatch(handleRefreshTareas(perfilUsuario.id))
              }, 1500);
            }
          })
        }
      },
      {
        key: 'k3',
        description: 'Registrar permiso de exportación',
        action: () => {
          setDialogData({
            ...dialogData,
            message: 'Va a crear una nueva Solicitud de permiso de exportación de recursos (WF11)',
            messsageAfterOk: 'Se ha creado una nueva Solicitud de permiso de exportación de recursos (WF11)',
            openDialog: true,
            okAction: () => {
              const p = JSON.parse(aprobada.payloadSolicitud)
              const solicitudAprobada = p.Solicitud
              solicitudAprobada.recursosES = [...solicitudAprobada.recursosES]?.map(it => ({
                ...it,
                cantidadAutorizada: it.cantidadSolicitada,
                cantidadSolicitada: 0,
              }))
              const solicitudPermiso = JSON.parse(solicitudAprobada.solicitudAprobada)
              let sp = {
                solicitudNumeroSolicitud: aprobada.solicitudNumeroSolicitud,
                payloadSolicitud: JSON.stringify({
                  Solicitante: solicitudPermiso.Solicitante,
                  Propuesta: solicitudPermiso.Propuesta,
                  Personal: solicitudPermiso.Personal,
                  // father: {aprobada},
                  father: {aprobada, payloadSolicitud:{Solicitud:solicitudAprobada}},
                })
              }
              dispatch(handleCrearProceso(55000011, perfilUsuario?.id, perfilUsuario?.idPerfilUsuario, sp))
              setTimeout(function() {
                dispatch(handleRefreshTareas(perfilUsuario.id))
              }, 1500);
            }
          })
        }
      }
    ]
    if(esExsitu) {
      if(!hayHolotipos) {
        return myMenu.filter(it => it.key === 'k1')
      }
      if(hayHolotipos){
        return myMenu.filter(it => it.key === 'k1' || it.key === 'k2')
      }
    } else {
        return myMenu.filter(it => it.key === 'k3')
      }
  }

  return (
    <>
      <Grid item xs={3} sx={{p:'0 0.5rem 0.5rem 0.5rem'}}>
        <Card variant="outlined" sx={{borderWidth: '1px', borderColor: color02}}>
          <MyCardHeader bgColor={color02}
                        color={'white'}
                        cardTitle={aprobada.nombreProceso}
                        menu={suspendida?null:(aprobada.idProceso !== 55000004)?menu0710():null}
                        state={{
                          state: (!aprobada.suspendidaSolicitud ? 'aprobada' : 'suspendida'),
                          tip: !aprobada.suspendidaSolicitud ? 'Aprobada' : 'Suspendida'
                        }} />
          <MyCardContent h1={aprobada.solicitudNumeroSolicitud}
                         h2={payloadSolicitud?.Solicitud?.fecha}
                         content={`${aprobada.nombreProyectoSolicitud}`} />
          <CardActions sx={{backgroundColor: 'white', borderTop: `1px solid ${color02}`}}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                  <Button size='small'
                          onClick={() => {
                            dispatch(fetchPayloadFromProceso(aprobada.id))
                            dispatch(handleSetBandeja('entrada'))
                            if(aprobada.idProceso === 55000004) {
                              setTimeout(() => navigate('/p07ro'), 1500)
                            }
                            else {
                              setTimeout(() => navigate('/p10ro'), 1500)
                            }
                            // TODO, hacer read only del 07 setTimeout(() => navigate('/p07'), 500)
                          }}
                          sx={{width: '40%', color: 'white', backgroundColor: color02}}
                          variant='outlined'>VER</Button>
                </Stack>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </Grid>
      {
        tareas.filter(it => it.instanciaProcesoNumeroSolicitudPadre === aprobada.solicitudNumeroSolicitud && it.instanciaProcesoSolicitudEstadoSolicitud !== "Denegada").map((tarea, tidx) => {
          // console.log(':: tarea ::', tarea)
          const noeditable = (tarea.estadoInstanciaProceso === "En Progreso" || tarea.estadoInstanciaProceso === "Completado") && tarea.estadoInstanciaTarea === "Finalizada"
          const aprobada = tarea.instanciaProcesoSolicitudEstadoSolicitud === "Aprobada"
          let hcolor = noeditable ? blue[500] : blue[800]
          let lcolor = noeditable ? blue[300] : blue[600]
          if(tarea.estadoInstanciaProceso === "Completado" && tarea.estadoInstanciaTarea === "Finalizada" && aprobada) {
            hcolor = color02
            // lcolor = 'color02'
          }
          const menu09 = (['Completado', 'Finalizado'].includes(tarea.estadoInstanciaProceso))?[
            {
              key: 'WF12',
              description: 'Retorno de holotipos',
              action: () => {
                setDialogData({
                  ...dialogData,
                  message: 'Va a crear una notificación de retorno de holotipos (WF12)',
                  messsageAfterOk: 'Se ha creado una nueva notificación de retorno de holotipos (WF12)',
                  openDialog: true,
                  okAction: () => {
                    API.fetchInstanciaTarea(tarea.id).then(tarea => {
                      API.fetchInstanciaProceso(tarea.idInstanciaProceso).then((proceso) => {
                        let p = JSON.parse(proceso.solicitud.payload)
                        // console.log('::: p :: ', p.solicitudId)
                        dispatch(handleCrearProceso12(proceso.id, perfilUsuario?.id,{Solicitud: p.Solicitud},p.solicitudId))
                        setTimeout(function() {
                          dispatch(handleRefreshTareas(perfilUsuario.id))
                        }, 1500)
                      })
                    })
                  }
                })
              }
            },
          ]:null

          const menu11 = (['Completado', 'Finalizado'].includes(tarea.estadoInstanciaProceso))?[
            {
              key: 'WF14',
              description: 'Informar resultados de exportación',
              action: () => {
                setDialogData({
                  ...dialogData,
                  message: 'Va a crear un nuevo informe de resultados de exportación de recursos(WF14)',
                  messsageAfterOk: 'Se ha creado un nuevo informe de resultados de exportación de recursos(WF14)',
                  openDialog: true,
                  okAction: () => {
                    API.fetchInstanciaTarea(tarea.id).then(tarea => {
                      API.fetchInstanciaProceso(tarea.idInstanciaProceso).then((proceso) => {
                        let p = JSON.parse(proceso.solicitud.payload)
                        dispatch(handleCrearProceso14(proceso.id, perfilUsuario?.id,{Solicitud: p.Solicitud},p.solicitudId))
                        setTimeout(function() {
                          dispatch(handleRefreshTareas(perfilUsuario.id))
                        }, 1500)
                      })
                    })
                  }
                })
              }
            },
          ]:null

          let solicitudId = tarea.instanciaProcesoSolicitudNumeroSolicitud.split('-')
          solicitudId = (solicitudId.length>0)?Number(solicitudId[solicitudId.length-1]):solicitudId
          const subtareas = tareas.filter(it => Number(it.instanciaProcesoNumeroSolicitudPadre) ===solicitudId)
          // const esWF08 = (tarea?.instanciaProcesoIdProceso === 55000007) && (tarea?.estadoInstanciaProceso === 'Completado') && (tarea?.estadoInstanciaTarea ===  'Finalizada')
          const esWF09 = (tarea?.instanciaProcesoIdProceso === 55000009) && (tarea?.estadoInstanciaProceso === 'Completado') && (tarea?.estadoInstanciaTarea ===  'Finalizada')
          const esWF11 = (tarea?.instanciaProcesoIdProceso === 55000011) && (tarea?.estadoInstanciaProceso === 'Completado') && (tarea?.estadoInstanciaTarea ===  'Finalizada')
          return (
            <>
              <Grid key={tidx} item xs={3} sx={{p:'0 0.5rem 0.5rem 0.5rem'}}>
                <Card variant="outlined" sx={{borderColor: hcolor}} key={tarea.instanciaProcesoSolicitudNumeroSolicitud}>
                  <MyCardHeader bgColor={hcolor}
                                color={'white'}
                                cardTitle={tarea.instanciaProcesoNombreProceso}
                                menu={esWF09?menu09:esWF11?menu11:esWF11?false:false}
                                state={{
                                  state:!noeditable ?'editable':'aprobada',
                                  tip:!noeditable ? 'Editable' : aprobada?'Aprobada':'En evaluación'
                                }} />
                  <MyCardContent h1={tarea.instanciaProcesoSolicitudNumeroSolicitud}
                                 h2={format(parseISO(tarea.fechaHoraInicia), 'dd MMMM yyyy hh:mm', {locale: es})}
                                 content={tarea.instanciaProcesoSolicitudNombreProyecto} />
                  <CardActions sx={{backgroundColor: 'white', borderTop: `1px solid ${hcolor}`}}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                          <Button size="small"
                                  sx={{ width: '40%', color: 'white', backgroundColor: `${hcolor}`}}
                                  onClick={() => {
                                    dispatch(fetchInstanciaTareaInstanciaProceso(tarea.id))
                                    if (!noeditable) {
                                      dispatch(handleSetBandeja('entrada'))
                                    } else {
                                      dispatch(handleSetBandeja('salida'))
                                    }
                                  }}
                                  variant={!noeditable ? 'contained' : 'text'}>
                            {!noeditable ? 'Editar' : 'Ver'}
                          </Button>
                        </Stack>
                      </Grid>
                    </Grid>
                  </CardActions>
                </Card>
              </Grid>
              {
                subtareas.map((mtarea, midx) => {
                  const noeditable = mtarea.estadoInstanciaTarea === "Finalizada"
                  const hcolor = noeditable?blue[500]:blue[800]
                  let proceso = {
                    'Permiso':   'Solicitud de Permiso de investigación',
                    'Contrato':  'Solicitud de Contrato de acceso con potencial uso comercial',
                    'Comercial': 'Solicitud de Contrato de acceso con fines comerciales',
                  }[mtarea.tipoInstanciaProceso]

                  if(!f.isValid(proceso)) {
                    proceso = mtarea.instanciaProcesoNombreProceso
                  }
                  // const lcolor = noeditable?blue[300]:blue[600]
                  return (
                    <Grid item key={`wf0101iniciadas-${midx}`} xs={2} sm={6} md={4} lg={3}>
                      <Card variant="outlined" sx={{borderColor: hcolor}}>
                        <MyCardHeader bgColor={hcolor}
                                      color={'white'}
                                      cardTitle={((mtarea.tareaCodigoTarea !== "55000014_Activity_RevisarContratoWF0102")?proceso:mtarea?.nombreTarea) + '***3'}
                                      menu={[]}
                                      state={{
                                        state:noeditable? 'noEditable' : 'editable',
                                        tip: noeditable ? 'En evaluación' : 'En elaboración',
                                      }} />
                        <MyCardContent h1={mtarea.instanciaProcesoSolicitudNumeroSolicitud}
                                       h2={format(parseISO(mtarea.fechaHoraInicia),'dd MMMM yyyy hh:mm', {locale: es})}
                                       content={mtarea?.instanciaProcesoSolicitudNombreProyecto} />
                        <CardActions sx={{backgroundColor: 'white', borderTop:`1px solid ${hcolor}`}}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                                <Button size='small'
                                        onClick={() => {
                                          dispatch(fetchInstanciaTareaInstanciaProceso(mtarea.id))
                                          dispatch(handleSetBandeja('salida'))
                                        }}
                                        sx={{width:'40%', color:('white'), borderColor:hcolor}}
                                        variant='contained'>ver</Button>
                                {
                                  !noeditable?
                                    <Button size="small"
                                            sx={{width:'40%', color:(!noeditable?'white':hcolor), borderColor:hcolor}}
                                            onClick={() => {
                                              if(!noeditable) {
                                                dispatch(fetchInstanciaTareaInstanciaProceso(mtarea.id))
                                                dispatch(handleSetBandeja('entrada'))
                                              }
                                            }}
                                            variant={!noeditable?'contained':'text'}>
                                      {(mtarea.tareaCodigoTarea !== "55000014_Activity_RevisarContratoWF0102")?'Editar':'Revisar'}
                                    </Button>:null
                                }
                              </Stack>
                            </Grid>
                          </Grid>
                        </CardActions>
                      </Card>
                    </Grid>
                  )
                })
              }
            </>
          )
        })
      }
      <MyConfirm dialogData={dialogData} setDialogData={setDialogData} />
    </>
  )
}
